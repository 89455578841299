import React, { Component, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Icon, Header, Image, Label, Button, Grid, Card, List } from 'semantic-ui-react';
import "./enrollmentQueue.css"
import { Link } from 'react-router-dom';
import { PersonRoles } from "../../../constants/roles";
import Moment from "moment-timezone";
import UUID from '../../UI/uuid';
import * as ROUTES from "../../../constants/routes";
import { useImageUrl  } from '../../Images';

const ConfidenceDetails = (props) => (
    <Card fluid color='red'>
        <Card.Content>
            <Card.Header>
                <Header as='h4'>
                    <Icon inverted color="red" name="exclamation circle" />
                    <Header.Content>Confidence below enrollment threshold</Header.Content>
                </Header>
            </Card.Header>
        </Card.Content>
        <Card.Content>
            <Header as='h4'>Visit confidence {parseFloat(Math.round(props.confidence * 10000) / 100).toFixed(2) + "%"} suggests person has prior visits.</Header>
        </Card.Content>
    </Card>
);

const UserDetails = (props) => {
    //const [variableName, setVariableStateFunction] = useState(defaultValue);
    const [isLatestVisitsLoading, setLatestVisitsLoadingState] = useState(false);
    const [compareLatestVisitLabel, setLatestVisitLabel] = useState('Compare latest visit')
    return (
        <Card fluid color='red'>
            <Card.Content>
                <Card.Header>
                    <Header as='h4'>
                        <Icon inverted color="red" name="exclamation circle" />
                        <Header.Content>Found {props.users.length} {props.namesFocused ? "people with this name" : "customer ID duplicates"}</Header.Content>
                    </Header>
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <Grid>
                    {
                        props.users.map(name => {
                            let data = name.data;
                            return (
                                <Grid.Row key={name.uuid} columns='2'>
                                    <Grid.Column width="1" verticalAlign="middle">
                                        <Icon name="user" size="large" />
                                    </Grid.Column>
                                    <Grid.Column width="15">
                                        <Header as="h4">
                                            {data.name}
                                            <Header.Subheader>
                                                <UUID value={name.uuid} />
                                            </Header.Subheader>
                                        </Header>
                                        <Label horizontal color={data.role === PersonRoles.default ? "orange" : "grey"} >{data.role}</Label>
                                        {data.customerId && <Label horizontal>Customer ID: <Label.Detail>{data.customerId}</Label.Detail></Label>}
                                        <Label horizontal>Enrolled: <Label.Detail>{Moment(data.enrollDate).format('l')}</Label.Detail></Label>
                                        <Label basic horizontal style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setLatestVisitsLoadingState(true);
                                                props.compareItemWithLatestVisit(props.enrollVisitID, name.uuid, setLatestVisitsLoadingState, setLatestVisitLabel);//USE HOOKS FOR LOADING "STATE"
                                            }
                                            }>
                                            {!isLatestVisitsLoading ? <Icon name='columns' color="violet" /> : <Icon name='spinner' loading color="violet" />} {compareLatestVisitLabel}
                                        </Label>
                                        <Label basic horizontal style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                let win = window.open(`${window.location.protocol}//${window.location.hostname + ROUTES.VISITS_BROWSER}?uuid=${name.uuid}`, '_blank');
                                                win.focus();
                                            }
                                            }>
                                            <Icon name='history' /> View previous visits
                                    </Label>
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })
                    }
                </Grid>
            </Card.Content>
        </Card>
    );
}

const NoCustomerId = (props) => (
    <Card fluid color='red'>
        <Card.Content>
            <Card.Header>
                <Header as='h4'>
                    <Icon inverted color="red" name="exclamation circle" />
                    <Header.Content>Customer ID is invalid</Header.Content>
                </Header>
            </Card.Header>
        </Card.Content>
    </Card>
);

const PreviousVisitItem = (props) => {
    let { data } = props;
    let { images, location } = data;
    const thumbnailUrl = useImageUrl(images[0].mediaUuid, 'thumb');

    return (
        <>
            <Grid.Row verticalAlign="middle">
                <Header as="h4">
                    {data.localDate}
                    <Header.Subheader>
                        <Icon fitted name="map marker alternate" /> {location.name + ", " + location.region}
                    </Header.Subheader>
                </Header>
            </Grid.Row>
            <Grid.Row>
                <Image rounded src={thumbnailUrl} fluid />
            </Grid.Row>

            
        </>
    )
}

const PreviousVisits = props => {
    return (
        <Card fluid color="red">
            <Card.Content>
                <Card.Header>
                    <Header as='h4'>
                        <Icon inverted color="red" name="exclamation circle" />
                        <Header.Content>Found {props.visits.length} previous visit(s)</Header.Content>
                    </Header>
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <List horizontal className="horizontal-list">
                    { props.visits.map(visit => <List.Item key={visit.id} >
                                <PreviousVisitItem id={visit.id} data={visit.data} />>
                                <Grid.Row verticalAlign="middle" textAlign="center" style={{ marginTop: "5px" }}>
                                    <Button basic fluid as={Link} to={'/v/' + props.enrollVisitID + '/compareWith/' + visit.id} target="_blank" color="orange">Compare <Icon name="chevron right" /></Button>
                                </Grid.Row>
                            </List.Item>)
                    }
                </List>
            </Card.Content>
        </Card>
    );
};
class EnrollmentPrecheckDetails extends Component {
    static propTypes = {
        details: PropTypes.object.isRequired,      // The pre-check information
        enrollVisitID: PropTypes.string.isRequired,      //enrollVisitID: The visit ID in the enrollment queue
    };

    render() {
        const { details, enrollVisitID, compareItemWithLatestVisit } = this.props;
        return (
            <div>
                {!details.checkStatus.confidence && details.confidence && <ConfidenceDetails confidence={details.confidence} />}

                {!details.checkStatus.name && details.name && <UserDetails namesFocused users={details.name} enrollVisitID={enrollVisitID} compareItemWithLatestVisit={compareItemWithLatestVisit} />}

                {!details.checkStatus.customerId && details.customerId && <UserDetails users={details.customerId} enrollVisitID={enrollVisitID} compareItemWithLatestVisit={compareItemWithLatestVisit} />}
                {!details.checkStatus.customerId && !details.customerId && <NoCustomerId />}

                {!details.checkStatus.visit && details.visit && <PreviousVisits visits={details.visit} enrollVisitID={enrollVisitID} />}
            </div>
        );
    }
}

export default EnrollmentPrecheckDetails;   