import React from 'react';
import { List, Image } from 'semantic-ui-react';
import { useFaceImageUrl } from '../../Images';

const IdentificationItem = props => {
    const { identification, imageSize, isMaxConfidence, showImage } = props;
    const faceImageUrl = useFaceImageUrl(identification.mediaUuid, identification.faceId);

    return (
        <>
            {faceImageUrl && showImage && <Image
                src={faceImageUrl}
                size={imageSize}
                className={isMaxConfidence ? "best-ident-face" : ""}
                rounded
            />}
        </>
    )
}

const IdentificationsList = props => {
    let { list: identifications, compact, maxConfidence, showImages } = props;
    return (
        <List horizontal className="horizontal-list">
            {identifications.map(identification =>
                <List.Item key={identification.faceId} className="hl-item">
                    <IdentificationItem
                        identification={identification}
                        showImage={showImages}
                        imageSize={compact ? "mini" : "tiny"}
                        isMaxConfidence={identification.confidence === maxConfidence}
                    />
                </List.Item>
            )}
        </List>
    );
}

export default IdentificationsList;