import app from 'firebase/app';
import 'firebase/firestore';

const COLL_NAME_IMAGES = 'media';


class MediaData {
    constructor(caazamAPI) {
        this.db = app.firestore().collection(COLL_NAME_IMAGES);
        this.caazamAPI = caazamAPI;
    }

    async fetchThis(mediaUuid, options) {
        try {
            let doc = await this.db.doc(mediaUuid).get();
            if (doc.exists) {
                return { id: doc.id, data: doc.data() };
            }
            else {
                throw new Error(`"${mediaUuid}" is not a valid media ID`)
            }
        } catch (error) {
            console.error(`Error fetchThis():`, error);
            throw error;
        }
    }

    async fetch(options) {
        return this._fetchPaginated(options, null);
    }

    async _fetchPaginated(options, cursor) {
        var nextPage = null, latest;
        let _options = Object.assign({}, {
            // startTimestamp: mandatory,
            // stopTimestamp: optional,
            // locationId: optional, 
            // limit: optional,
            detectedOnly: true,
            identifiedOnly: false,
        }, options);

        let query = this.db.where('timestamp', '>', _options.startTimestamp);
        if (_options.stopTimestamp) {
            query = query.where('timestamp', '<=', _options.stopTimestamp);
        }
        if (_options.locationId) {
            query = query.where('source.locationId', '==', _options.locationId);
        }

        if (_options.identifiedOnly) {
            query = query.where('detectionsSummary.facesIdentified', '==', true);
        } else if (_options.detectedOnly) {
            query = query.where('detectionsSummary.facesDetected', '==', true);
        }

        if (cursor) {
            query = query.startAfter(cursor);
        }

        if (_options.limit) {
            query = query.limit(_options.limit);
        }

        try {
            let querySS = await query.get();
            var entries = querySS.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            if (querySS.size === _options.limit) {
                let lastItem = querySS.docs[querySS.size - 1];
                nextPage = () => this._fetchPaginated(_options, lastItem);
            }
            
            let detectionsRequests = entries.map(async media => {
                let detectionDoc = await this.db.doc(media.id).collection('detections').get();
                return detectionDoc.docs.reduce((agg, doc) => {
                    agg[doc.id] = doc.data();
                    return agg;
                },{});
            });
            let detections = await Promise.all(detectionsRequests);
            entries.forEach((media, index) => {
                media.data.detections = detections[index];
            }); 

            return { media: entries, nextPage: nextPage };
        } catch (error) {
            console.error(`MediaData _fetchPaginated():`, error);
            throw error;
        };
    }
}

export default MediaData;
