import React, { useState, useEffect } from 'react';
//import { PropTypes } from 'prop-types';
import Moment from "moment-timezone";

import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import { Modal, Button } from 'semantic-ui-react';
import { RichGrid } from '../CaazamUI';

import RecentItem from './VisitFeedComponents/recentItem';


const AllPurchasedItemsModal = props => {
    const [productHistory, setProductHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const { firebase, person } = props;

    useEffect(() => {
        const getPersonPurchases = async (person) => {
            try {
                const purchases = await firebase.caazamAPI.getPersonPurchases({ uuid: person.uuid, customerId: person.customerId });
                setProductHistory(purchases);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            };
        }
        getPersonPurchases(person);
    }, [person, firebase.caazamAPI])

    return (
        <Modal open={true} >
            <Modal.Header>{props.person.name} - Recent purchases <Button floated="right" onClick={() => props.onModalClose(false)}>Close</Button> </Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    {loading && <center>Loading products history...</center>}
                    {productHistory && productHistory.length > 0 &&
                        <center>
                            <div style={{ marginLeft: '150px', marginRight: '150px' }}>
                                <RichGrid alignment="center" gridSize="5">
                                    <RichGrid.Layout>
                                        {
                                            productHistory.map((product, index) =>
                                                <RecentItem product={product} person={props.person} key={index} />
                                            )
                                        }
                                    </RichGrid.Layout>
                                </RichGrid>
                            </div>
                        </center>
                    }
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(AllPurchasedItemsModal);