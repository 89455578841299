import React from 'react';
import { Image, Table, Icon, Grid, Header, Message } from 'semantic-ui-react';
import { FeedbackTypes } from "../../../../constants/feedback";
import { PropTypes } from "prop-types";

import Moment from "moment-timezone";
import UUID from '../../../UI/uuid';
import { useFaceImageUrl, openFullpageImage  } from '../../../Images';

const gridRowStyle = { paddingBottom: '4px', paddingTop: '4px' };



const FeedbackSummaryForSales = (props) =>{

    let { reportMetadata, feedbackData, notesData, sales, slim } = props;

    return (
        <Table celled color={!slim ? 'black' : null} basic={slim ? "very" : null}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={slim ? "7" : "6"}>{slim ? "Info" : "Sale Details"}</Table.HeaderCell>
                    <Table.HeaderCell width={slim ? "6" : "7"}>{slim ? "Notes" : "Info"}</Table.HeaderCell>
                    <Table.HeaderCell width={slim ? "1" : "2"} textAlign="center"><Icon name='user circle outline' /></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {sales.map(sale =>
                    <Table.Row key={sale.id}>
                        {!!feedbackData[sale.id] && <FeedbackTableRow feedback={feedbackData[sale.id]} note={notesData[sale.id]} sale={sale}  metadata={reportMetadata} />}
                        {!feedbackData[sale.id] && !!notesData[sale.id] && <NotesTableRow note={notesData[sale.id]} sale={sale} metadata={reportMetadata} />}
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}

FeedbackSummaryForSales.propTypes = {
    feedbackData: PropTypes.object.isRequired,      // Report's feedback metadata
    reportMetadata: PropTypes.object.isRequired,    // reports metadata
    sales: PropTypes.array.isRequired,              // the sales in the report for additional data
    notesData: PropTypes.object,                    //notesData: Notes in this report
    slim: PropTypes.bool,                           //slim: Slim lines (shows only one face instead of thumbnail)
}

const NotesTableRow = props => {
    let { note, sale, metadata } = props;
    return (
        <>
            <Table.Cell verticalAlign='top'>
                <Grid padded>
                    <Grid.Row style={gridRowStyle}>
                        <Header as="h5">Transaction ID: <Header.Subheader>{sale.data.saleCustomerId}</Header.Subheader></Header>
                    </Grid.Row>
                    <Grid.Row style={gridRowStyle}>
                        <Header as="h5">
                            <Header.Subheader>
                                {Moment(sale.data.timestamp.toDate()).tz(metadata.scope.location.timezone).format('l LT')}
                                {sale.data.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}
                            </Header.Subheader>
                        </Header>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
            <Table.Cell verticalAlign='top' >
                <Grid padded>
                    <Grid.Row style={gridRowStyle}>
                        <Message warning><cite>{note.text}</cite></Message>
                    </Grid.Row>
                </Grid>
            </Table.Cell>
        </>
    );
}



const FeedbackTableRow = props => {
    let { feedback, note, sale, openFullResImage, metadata } = props;
    let visit = feedback.data.candidateData;
    const mediaUuidForImage = visit.images[0] ? visit.images[0].mediaUuid : null;
    const faceIdForImage = visit.images[0] ? visit.images[0].faceId : null;
    let faceImageUrl = useFaceImageUrl(mediaUuidForImage, faceIdForImage);
    return (
        <>
            {
                sale && feedback.type === FeedbackTypes.candidate ?
                    <>
                        <Table.Cell verticalAlign='top'>
                            <p><b>Transaction ID: </b>{sale.data.saleCustomerId}</p>
                            <p><b>Sale Amount: </b>{sale.data.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}</p>
                            <p><b>Sale Time:  </b>{Moment(sale.data.timestamp.toDate()).tz(metadata.scope.location.timezone).format('l LT')}</p>
                            <p><b>Customer Name: </b>{sale.data.customerName}</p>
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' >
                            <Grid padded>

                                <Grid.Row>
                                    This sale was identified as the following person:
                                        </Grid.Row>
                                <Grid.Row style={gridRowStyle}>
                                    Name: {visit.person.name}
                                </Grid.Row>
                                <Grid.Row style={gridRowStyle}>
                                    UUID: <UUID value={visit.person.uuid} />
                                </Grid.Row>
                                <Grid.Row style={gridRowStyle}>
                                    Visit Time: {visit.localDate}
                                    {/* Moment(feedback.data.candidateData.date).tz(visit.data.location.timezone).format('l, LT')} (sale with{Moment(feedback.data.candidateData.date).tz(visit.data.location.timezone).from(Moment(visit.data.timestamp.toDate()).tz(visit.data.location.timezone))*/}
                                </Grid.Row>
                                {note &&
                                    <Grid.Row style={gridRowStyle}>
                                        <Message warning><cite>{note.text}</cite></Message>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Table.Cell>
                        <Table.Cell verticalAlign='top' >
                            <Grid padded>
                                <Grid.Row style={gridRowStyle}>
                                    <center>
                                        <Image
                                            rounded
                                            size='small'
                                            src={faceImageUrl}
                                            onClick={() => openFullpageImage(visit.images[0].mediaUuid)}
                                        />
                                    </center>
                                </Grid.Row>
                            </Grid>
                        </Table.Cell>
                    </>
                    : note && <NotesTableRow note={note} visit={visit} openFullResImage={openFullResImage} />
            }
        </>
    );
}

export default FeedbackSummaryForSales;