import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { withAdminAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { Icon, Segment, Header, Button ,Checkbox, Grid } from 'semantic-ui-react';

import EnrollmentQueueList from "./enrollmentQueueList";
import { EnrollmentStatus, EnrollmentType } from '../../../constants/enrollment';
import { PersonRoles } from '../../../constants/roles';

const DEFAULT_QUEUE_FILTER = [EnrollmentType.enroll, EnrollmentType.false_positive];

const EnrollmentQueue = props => {
    const [queue, setQueue] = useState([]);                         // the enroll queue data
    const [queueFilter, setQueueFilter] = useState(DEFAULT_QUEUE_FILTER);           // enroll queue display filter
    const [queuedCounter, setQueuedCounter] = useState(0);          // enroll queue counter
    const [enrollContextId, setEnrollContextId] = useState(null);   // the user's enroll chnage context
    const [isProcessing, setProcessing] = useState(false);          // is enrollment in process
    const [isInProgress, setInProgress] = useState(false);          // is an request in progress
    const [errorMessage, setErrorMessage] = useState(null);         // error message

    //--------------- Firebase listeners' callbacks ---------------


    const onDatabaseError = error => {
        console.error(error);
    }

    useEffect(() => {
        let listener = props.firebase.enrollment.enrollContextListner(context => {
            console.log('context listner', context);
            if (!context) { // if no active context
                setEnrollContextId(null);
                setProcessing(false);
            } else {                        // if context is active and started
                setEnrollContextId(context.activeContext);
                setProcessing(!!context.startTimestamp);
            }                      
        }, onDatabaseError);
        return listener; // for cleanup;
    }, [props.firebase.enrollment])


    useEffect(() => {
        const onQueueUpdated = queue => {
            setQueue(queue);
        }

        let listener = props.firebase.enrollment.fetchActiveQueue(onQueueUpdated, onDatabaseError);
        return listener; // for cleanup;
    }, [props.firebase.enrollment])

    useEffect(() => {
        const onQueueCounterUpdated = count => {
            console.log('onQueueCounterUpdated', count);
            setQueuedCounter(count);
        }

        if (enrollContextId) {
            let countIfStatus = [EnrollmentStatus.queued, EnrollmentStatus.running];
            let listener = props.firebase.enrollment.countPendingEnrolls(onQueueCounterUpdated, onDatabaseError, countIfStatus, enrollContextId);
            return listener; // for cleanup;
        } else {
            setQueuedCounter(0);
        }
    }, [enrollContextId, props.firebase.enrollment])

    //--------------- onClick callbacks ---------------
    const onChangeSubmitted = (enrollID, newName, newCid, newRole, onResult) => {
        props.firebase.caazamAPI.enrollUpdate(enrollID, newName, newCid, newRole).then(response => {
            onResult(true, response);
        }).catch(error => {
            onResult(false, error);
        });
    }

    const onQueueFilterClick = (e, data) => {
        if (data.checked) {
            setQueueFilter(null);
        } else {
            setQueueFilter(DEFAULT_QUEUE_FILTER);
        }
    }

    // -------------------- API --------------------

    const onEnrollment = async enrollID => {
        setInProgress(true);
        return props.firebase.caazamAPI.enroll(enrollID).finally(() => setInProgress(false));
    }

    const onMerge = (enrollID, targetUuid, reason) => {
        setInProgress(true);
        return props.firebase.caazamAPI.enrollMerge(enrollID, targetUuid, reason).finally(() => setInProgress(false));
    }

    const onEnrollmentReset = enrollID => {
        setInProgress(true);
        return props.firebase.caazamAPI.resetEnroll(enrollID).finally(() => setInProgress(false));
    }

    const onEnrollmentRevert = enrollID => {
        setInProgress(true);
        return props.firebase.caazamAPI.revertEnroll(enrollID).finally(() => setInProgress(false));
    }

    const onEnrollmentCancel = (enrollID, reason, note) => {
        setInProgress(true);
        return props.firebase.caazamAPI.enrollCancel(enrollID, reason, note).finally(() => setInProgress(false));
    }

    const onExecute = async () => {
        try {
            setProcessing(true);
            setErrorMessage(null);
            await props.firebase.caazamAPI.executeEnrollQueue();
        } catch (error) {
            setErrorMessage(error.message);
        } 
    }

    const onNoteEdited = (enrollID, notes) => {
        props.firebase.caazamAPI.enrollUpdateNotes(enrollID, notes).catch(error => {
            console.error('Enrollment note has NOT been registerd', enrollID, notes, error);
        });
    }

    //-------------------------------------------------

    const compareItemWithLatestVisit = (enrollVisitsId, uuid, setLoadingCallback, setLabelCallback) => {
        props.firebase.visits.fetchLatestByUuid(uuid).then(latestVisitId => {
            setLoadingCallback(false);
            if (latestVisitId) {
                let win = window.open(`${window.location.protocol}//${window.location.hostname}/v/${enrollVisitsId}/compareWith/${latestVisitId}`, '_blank');
                win.focus();
            }
            else {
                setLabelCallback("No previous visits");
            }

        }).catch(error => {
            setLoadingCallback(false);
            setLabelCallback(error.message);
        })
    }

    var filteredQueue = queue;
    if (queueFilter) {
        filteredQueue = queue.filter(item => queueFilter.includes(item.data.type) || !item.data.type)
    }

    return (
        <div>
            <Grid columns='2'>
                <Grid.Column width='10'>
                    <Segment basic >
                        <Header as="h2">Enrollment Queue </Header>
                        <Checkbox label='Show anonymous' onClick={onQueueFilterClick} />
                    </Segment>
                </Grid.Column>
                <Grid.Column width='6' textAlign='right'>
                    <Segment basic >
                        {queuedCounter > 0 &&
                            <Button icon labelPosition='left' color='orange' onClick={() => onExecute()} disabled={isProcessing || isInProgress} loading={isProcessing}>
                                {!isProcessing && <Icon name='list' />}
                                {isProcessing && <Icon name='spinner' loading />}
                                {queuedCounter} items in queue
                    </Button>}

                        {queuedCounter === 0 &&
                            <Button icon labelPosition='left' color='green'>
                                <Icon fitted name='check' />
                        queue is empty
                </Button>}
                    </Segment>
                </Grid.Column>
            </Grid>
            {errorMessage && <Segment basic >
                <Header as="h5" color="red">{errorMessage}</Header>
            </Segment>}
            <EnrollmentQueueList
                queue={filteredQueue}
                onEnrollEdited={onChangeSubmitted}
                editNote={onNoteEdited}
                onEnrollment={onEnrollment}
                onEnrollmentReset={onEnrollmentReset}
                onEnrollmentRevert={onEnrollmentRevert}
                onEnrollmentCancel={onEnrollmentCancel}
                onMerge={onMerge}
                isProcessing={isProcessing}
                compareItemWithLatestVisit={compareItemWithLatestVisit} />
        </div>

    );
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(EnrollmentQueue);