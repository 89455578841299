import React from 'react';
import { Label, Segment, List, Header, Icon } from 'semantic-ui-react';
import QueueItem from './queueItem';

const OperationsQueue = (props) => {
    const { onClose, operationsToLstn, remainingOps } = props;
    const queueAccent = props.remainingOps === 0 ? "green" : "orange";
    const allowClose = props.remainingOps === 0;

    let keys = Object.keys(operationsToLstn);
    return (
        <Segment.Group>
            <Header color={queueAccent} block attached="top">
                {allowClose && <div onClick={onClose}><Icon bordered inverted fitted size="small" color='grey' name='x' className="close-button" /></div>}
                <Header.Content >
                    <Label empty circular color={queueAccent} /> Operations queue
                        <Header.Subheader>{remainingOps > 0 ? remainingOps + " operation(s) in progress" : "Done!"} </Header.Subheader>
                </Header.Content>
            </Header>
            <Segment attached="bottom" className="ops-queue-list">
                <List divided>
                    {keys.length === 0 && <Label attached="top"><h3>No operations in progress</h3></Label>}
                    {keys.map((opKey) => (
                        <List.Item key={opKey}>
                            <QueueItem
                                operationID={opKey}
                                operation={operationsToLstn[opKey].operationData}
                                operationendVisit={operationsToLstn[opKey].opedVisitData} />
                        </List.Item>
                    ))}
                </List>
            </Segment>
        </Segment.Group>
    );
}

export default OperationsQueue;