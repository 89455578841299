import React, { useState } from 'react';
import { Image, Icon, Button, Modal, Input } from "semantic-ui-react";

const MergeModal = props => {

    const { open, uuid, thumbnailURL } = props;
    const [mergeToUuid, setMergeToUuid] = useState(null);

    const mergeOnClick = () => {
        props.action(mergeToUuid);
    }

    const cancelOnClick = () => {
        props.onCancel();
    }

    return (
        <Modal size="tiny" open={open}>
            <Modal.Header>                
                Merge {uuid}
                <Image src={thumbnailURL} rounded />
            </Modal.Header>
            <Modal.Content>

                <Input focus fluid placeholder='Merge to UUID?' onChange={(e, data) => setMergeToUuid(data.value)} error={!mergeToUuid}/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={cancelOnClick}>Cancel</Button>
                <Button icon='sign in alternate' labelPosition='left' content='Merge' onClick={mergeOnClick} disabled={!mergeToUuid} />
            </Modal.Actions>
        </Modal>
    );
}


export default MergeModal;