
import React, { useState, useEffect } from 'react';
import Firebase from '../Firebase';

const imageUrlbuilder = (token, mediaUuid, faceId, size) => {
    
    var url = new URL(process.env.REACT_APP_CAAZAM_REST_HOST + '/images/' + mediaUuid);
    if (faceId) {
        url.pathname += `/face/${faceId}`;
    }
    let searchParams = {
        token,
    }
    if (size) {
        searchParams.size = size;
    }
    url.search = new URLSearchParams(searchParams);
    return url.toString();
}

const getImageUrl = async (mediaUuid, size) => {
    let useSize = size ? size : 'web';
    try {
        let token = await Firebase.auth.currentUser.getIdToken();
        return imageUrlbuilder(token, mediaUuid, null, useSize);
    } catch (error) {
        console.error(`getImageUrl(): ${mediaUuid} ${useSize}`, error);
        return '';
    }
}

const getFaceImageUrl = async (mediaUuid, faceId) => {
    try {
        let token = await Firebase.auth.currentUser.getIdToken();
        return imageUrlbuilder(token, mediaUuid, faceId);
    } catch (error) {
        console.error(`useImageUrl(): ${mediaUuid} ${faceId}`, error);
        return '';
    }
}

const useImageUrl = (mediaUuid, size) => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        const getUrl = async (mediaUuid, size) => {
            let url = await getImageUrl(mediaUuid, size);
            setUrl(url);
        }
        if (mediaUuid) {
            getUrl(mediaUuid, size);
        }
    }, [mediaUuid, size]);

    return url;
}

const useFaceImageUrl = (mediaUuid, faceId) => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        const getUrl = async (mediaUuid, faceId) => {
            let url = await getFaceImageUrl(mediaUuid, faceId);
            setUrl(url);
        }
        if (mediaUuid && faceId) {
            getUrl(mediaUuid, faceId);
        }
    }, [mediaUuid, faceId]);

    return url;
}

const openFullpageImage = async (mediaUuid) => {
    try {
        let url = await getImageUrl(mediaUuid, 'original');
        let win = window.open(url, '_blank');
        win.focus();
    } catch (error) {
        console.error(`openFullpageImage(): ${mediaUuid}`, error);
    }
}

export {
    getImageUrl,
    useImageUrl,
    getFaceImageUrl,
    useFaceImageUrl,
    openFullpageImage,
}