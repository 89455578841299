import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Table, Radio, Input, Icon, Header, Form } from 'semantic-ui-react';
import Moment from "moment-timezone";
import { withFirebase } from "../../Firebase";

import { PersonRoles } from '../../../constants/roles'
import { FeedbackTypes } from '../../../constants/feedback';
import { Note } from '../../UI';

const SELECT_ID_CORRECT = 'TRUE';
const SELECT_ID_INCORRECT = 'FALSE';

class OptionsForAnonymous extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otherText: props.otherText || '',
            otherTextSet: false,
            // filter out some candidates
            candidatesToDisplay: props.candidates.filter(candidate => candidate.data.customerName.toLowerCase() !== 'counter sales' && !candidate.data.strongMatch),
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const newText = nextProps.otherText || prevState.otherText || '';
        return {
            otherText: newText,
            otherTextSet: newText !== '',
            // filter out some candidates
            candidatesToDisplay: nextProps.candidates.filter(candidate => candidate.data.customerName.toLowerCase() !== 'counter sales' && !candidate.data.strongMatch),
        };
    }

    onOtherTextChange = (e, data) => {
        let text = data.value;
        this.setState({ otherText: text });
    }

    onOtherTextSet = () => {
        if (this.state.otherText !== '') {
            this.setState({ otherTextSet: true });
            this.props.otherCandidateOnSet(this.state.otherText);
            //this.props.onPreventAction(false);
        }
        else {
            //this.props.onPreventAction(true);
            this.setState({ otherTextSet: false });
        }
    }

    onRadioSelect = (e, data) => {
        if (data.value !== FeedbackTypes.other) {
            this.setState({ otherTextSet: false, otherText: '' });
            //this.props.onPreventAction(false);
        }
        else {
            if (this.state.otherText === '') {
                //this.props.onPreventAction(true);
            }
            else {
                //this.props.onPreventAction(false);
            }
        }
        this.props.onRadioChecked(data.value);
    }

    addNote = note => {
        this.props.onNoteAdded(note);
        this.setState({ isNoteModalShown: false });
    }

    submitOnKey = e => {
        if (e.key === 'Enter') {
            this.onOtherTextSet();
        }
    }

    render() {

        let { visitId, selectedCandidate, timezone, visitMoment, editMode } = this.props;
        let { candidatesToDisplay, otherText, otherTextSet } = this.state;

        return (
            <div>
                <Table unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Sale Record Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>
                                <Radio
                                    name={'selectCandidate_' + visitId}
                                    value={FeedbackTypes.unknown}
                                    checked={selectedCandidate === FeedbackTypes.unknown}
                                    onChange={this.onRadioSelect}
                                    disabled={!editMode}
                                />
                            </Table.Cell>
                            <Table.Cell>I don't know this person</Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>

                        {candidatesToDisplay.map(candidate => (
                            <Table.Row key={candidate.id}>
                                <Table.Cell collapsing>
                                    <Radio
                                        name={'selectCandidate_' + visitId}
                                        value={candidate.id}
                                        checked={selectedCandidate === candidate.id}
                                        onChange={this.onRadioSelect}
                                        disabled={!editMode}
                                    />
                                </Table.Cell>
                                <Table.Cell>{candidate.data.customerName}</Table.Cell>
                                <Table.Cell>{Moment(candidate.data.date).tz(timezone).format('l, LT')} <br /> sale {Moment(candidate.data.date).tz(timezone).from(visitMoment)}</Table.Cell>
                            </Table.Row>
                        ))}

                        <Table.Row error={selectedCandidate === FeedbackTypes.other && otherText === ''}>
                            <Table.Cell collapsing>
                                <Radio
                                    name={'selectCandidate_' + visitId}
                                    value={FeedbackTypes.other}
                                    checked={selectedCandidate === FeedbackTypes.other}
                                    onChange={this.onRadioSelect}
                                    disabled={!editMode}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <span style={{ paddingRight: '8px' }}>Other:</span>
                                {editMode && <Input
                                    value={otherText}
                                    iconPosition='left'
                                    icon={otherTextSet && <Icon name='checkmark' color='green' />}
                                    disabled={selectedCandidate !== FeedbackTypes.other}
                                    placeholder='Customer full name'
                                    onBlur={this.onOtherTextSet}
                                    onChange={this.onOtherTextChange}
                                    onKeyPress={this.submitOnKey}
                                    error={selectedCandidate === FeedbackTypes.other && otherText === ''}
                                />}
                                {
                                    selectedCandidate === FeedbackTypes.other && otherText === '' &&
                                    <p>Name cannot be empty</p>
                                }
                                {!editMode && this.state.otherText}
                            </Table.Cell>
                            <Table.Cell textAlign="center"><Icon name="asterisk" /></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>

        );
    }
}

const OptionsForIdentified = (props) => (
    <div style={{ paddingBottom: '16px' }}>
        <Form  >
            <Header as="h4">
                Feedback
            </Header>
            <Form.Field>
                <Radio
                    name='someoneElseRadio'
                    label="This is the right person"
                    value={SELECT_ID_CORRECT}
                    checked={props.isIdentificationCorrect}
                    onChange={props.onIdentificationResultRadioChecked}
                    disabled={!props.editMode}
                />
            </Form.Field>
            <Form.Field>
                <Radio
                    name='someoneElseRadio'
                    label="This is someone else"
                    value={SELECT_ID_INCORRECT}
                    checked={!props.isIdentificationCorrect}
                    onChange={props.onIdentificationResultRadioChecked}
                    disabled={!props.editMode}
                />
            </Form.Field>
        </Form>
    </div>
);


class ReportFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCandidate: FeedbackTypes.unknown,
            otherCandidateText: '',
            isIdentificationCorrect: true,
            strongMatch: null,
            editMode: false,
        }

        this.unsubscribeNotes = null;
        if (props.reportFeedback && props.reportFeedback.type === FeedbackTypes.other) {
            //!props.reportFeedback.data || props.reportFeedback.data === '' ? props.onPreventAction(true) : props.onPreventAction(false);
        }
    }

    static propTypes = {
        visitId: PropTypes.string.isRequired,   // the visit ID
        reportId: PropTypes.string.isRequired,  // the report ID
        reportFeedback: PropTypes.object,       // current reportFeedback
        reportNotes: PropTypes.object,         // current report notes
        candidates: PropTypes.array,            // visit candaidates to display
        transactionDataDelegate: PropTypes.func.isRequired,
        visitRole: PropTypes.string,            // visit role type
        visitMoment: PropTypes.instanceOf(Moment), // visit moment
        visitTz: PropTypes.string,               // visit timezone
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        var selectedCandidate = FeedbackTypes.unknown;
        var otherCandidateText = '';
        if (nextProps.reportFeedback && nextProps.reportFeedback.type === FeedbackTypes.candidate) {
            selectedCandidate = nextProps.reportFeedback.data.candidateId;
        } else if (nextProps.reportFeedback && nextProps.reportFeedback.type === FeedbackTypes.other) {
            selectedCandidate = FeedbackTypes.other;
            otherCandidateText = nextProps.reportFeedback.data || '';

        }

        return {
            selectedCandidate: selectedCandidate,
            otherCandidateText: otherCandidateText,
            isIdentificationCorrect: (nextProps.reportFeedback) ? nextProps.reportFeedback.identifiedResult : true,
            strongMatch: ReportFeedback.findStrongMatch(nextProps.candidates),
            editMode: nextProps.editMode || false,
        };        
    }

    static findStrongMatch = candidates => {
        for (let candidate in candidates) {
            if (candidates[candidate].strongMatch) {
                return candidates[candidate];
            }
        }
        return null;
    }

    onRadioChecked = (value) => {
        this.setState({
            selectedCandidate: value,
            otherCandidateText: value !== FeedbackTypes.other ? '' : this.state.otherCandidateText,
        })
        var feedbackUpdate = {
            visitRole: this.props.visitRole,
            identifiedResult: this.state.isIdentificationCorrect,
        };

        //find the candidate info for the visit:
        if (value !== FeedbackTypes.unknown && value !== FeedbackTypes.other) {
            let index = this.props.candidates.findIndex(candidate => candidate.id === value);
            if (index >= 0) {
                feedbackUpdate.type = FeedbackTypes.candidate;
                feedbackUpdate.data = {
                    candidateId: this.props.candidates[index].id,
                    candidateData: this.props.candidates[index].data,
                }
            }
        } else {
            feedbackUpdate.type = value;
        }

        this.props.firebase.reports.visitFeedback.set(this.props.reportId, this.props.visitId, feedbackUpdate).catch(error => {
            console.error('Report feedback has NOT been registerd', this.props.reportId, this.props.visitId, feedbackUpdate, error);
        });
    }

    onIdentificationResultRadioChecked = (e, data) => {
        let isIdentificationCorrect = data.value === SELECT_ID_CORRECT;
        this.setState({ isIdentificationCorrect: isIdentificationCorrect, selectedCandidate: FeedbackTypes.unknown, otherCandidateText: '' });

        var feedbackUpdate = {
            identifiedResult: isIdentificationCorrect,
            visitRole: this.props.visitRole,
        };

        if (!isIdentificationCorrect) {
            feedbackUpdate.type = FeedbackTypes.unknown;
        }
        else {
            //this.props.onPreventAction(false);
        }


        this.props.firebase.reports.visitFeedback.set(this.props.reportId, this.props.visitId, feedbackUpdate).catch(error => {
            console.error('Report feedback has NOT been registerd', this.props.reportId, this.props.visitId, feedbackUpdate, error);
        });
    }

    onNoteEdited = note => {
        this.props.firebase.reports.visitNotes.set(this.props.reportId, this.props.visitId, note).catch(error => {
            console.error('Report feedback has NOT been registerd', this.props.reportId, this.props.visitId, note, error);
        });
    }

    /*
    otherCandidateOnChange = (e, data) => {
        this.setState({ selectedCandidate: FeedbackTypes.other, otherCandidateText: data.value })
    }
    */

    otherCandidateOnSet = (otherText) => {
        this.props.firebase.reports.visitFeedback.updateOther(this.props.reportId, this.props.visitId, otherText);
    }


    render() {
        let { visitId, candidates, visitRole, visitTz, reportNotes } = this.props;
        let { strongMatch, editMode } = this.state;

        return (
            <div>
                {strongMatch &&
                    <Header as="h4" color="orange">
                        {strongMatch.data.customerName} made a purchase here at {Moment(strongMatch.data.date).tz(visitTz).format('LT')} ({Moment(strongMatch.data.date).tz(visitTz).from(this.props.visitMoment)})
                    </Header>
                }
                {visitRole !== PersonRoles.anonymous &&
                    <OptionsForIdentified
                        isIdentificationCorrect={this.state.isIdentificationCorrect}
                        onIdentificationResultRadioChecked={this.onIdentificationResultRadioChecked}
                        editMode={editMode}
                    />}
                {(visitRole === PersonRoles.anonymous || !this.state.isIdentificationCorrect) &&
                    <div>
                        <Header as='h4'>Who is this person?</Header>
                        <div className="scrollable-feedback">
                            <OptionsForAnonymous
                                visitId={visitId}
                                candidates={candidates}
                                selectedCandidate={this.state.selectedCandidate}
                                timezone={visitTz}
                                visitMoment={this.props.visitMoment}
                                onRadioChecked={this.onRadioChecked}
                                otherText={this.state.otherCandidateText}
                                otherCandidateOnSet={this.otherCandidateOnSet}
                                editMode={editMode}
                                onPreventAction={this.props.onPreventAction}
                            />
                        </div>
                    </div>
                }
                <br />
                <Note notes={reportNotes} editMode={editMode} onEdit={this.onNoteEdited} />
            </div>
        );
    }
}

export default withFirebase(ReportFeedback);