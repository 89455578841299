import React, { useState, useEffect } from "react";
import "./visitView.css";
import { compose } from "recompose";
import { withFirebase } from "../../Firebase";
import Moment from "moment-timezone";
import { withRouter } from "react-router-dom";

import { Card, Grid, Icon, Label, Button, Modal, Header, Image } from "semantic-ui-react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useFaceImageUrl } from '../../Images'
import DeleteModal from "./deleteModal";
import MergeModal from "./mergeModal";
import VisitMetadataGlance from "./visitItemMetadataGlance";
import ThumbnailPanel from "./visitItemThumbnailPanel";
import VisitDetails from "./visitDetails";
import * as ROUTES from "../../../constants/routes";


const VisitView = props => {
  let { visit, editMode, children, allowComparison, compareWith } = props;
  const visitData = visit.data;
  

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteWorking, setDeleteWorking] = useState(false)

  const [openMergeModal, setOpenMergeModal] = useState(false)
  const [mergeWorking, setMergeWorking] = useState(false)

  //const [isSomoneElse, setIsSomoneElse] = useState(false)
  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState(false)
  const [copiedLink, setCopiedLink] = useState(false)
  const [copiedVisitId, setCopiedVisitId] = useState(false)
  //const [compareWith, setCompareWith] = useState(null)
  const [isComparedTo, setIsComparedTo] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)


  useEffect(() => {
    if (!props.compareWith) {
      setIsComparedTo(false);
    }
  },[props.compareWith]);


  const deleteThisVisit = () => {
    setDeleteWorking(true);
    setOpenDeleteModal(false);
    props.deleteVisit(visit.id, {
      mediaUuid: visitData.images[imageIndex].mediaUuid,
    });
  }

  const mergeThisVisit = async (mergeToUuid) => {

    if (props.anonMergeVisit) {
      setMergeWorking(true);
      setOpenMergeModal(false);
      try {
        await props.anonMergeVisit(visit.id, visitData.person.uuid, mergeToUuid);
      } catch (error) {
        console.error('mergeThisVisit', error);
      } finally {
        setMergeWorking(false);
      }
  }
  }

  const setVisitCompared = () => {
    if (isComparedTo) {
      setIsComparedTo(false);
      props.setVisitCompared(null);
    }
    else {
      setIsComparedTo(true);
      props.setVisitCompared(visit.id);
    }
  }

  const doCompare = () => {
    let win = window.open(`${window.location.protocol}//${window.location.hostname}/v/${compareWith}/compareWith/${visit.id}`, '_blank');
    win.focus();
    setIsComparedTo(false);
    props.setVisitCompared(null);
  }

  const goToVisitsHistory = () => {
    let win = window.open(`${window.location.protocol}//${window.location.hostname + ROUTES.VISITS_BROWSER}?uuid=${visitData.person.uuid}`, '_blank');
    win.focus();
  }

  const onThumbnailError = () => {
    let newImageIndex = imageIndex + 1;
    if (newImageIndex <visitData.images.length) {
      setImageIndex(newImageIndex);
      return true;
    }
    return false;
  }

  let { person, location } = visitData;
  let visitMoment = Moment(visitData.timestamp.toDate()).tz(location.timezone);

  const mediaUuidForImage = visitData.images[imageIndex] ? visitData.images[imageIndex].mediaUuid : null;
  const faceIdForImage = visitData.images[imageIndex] ? visitData.images[imageIndex].faceId : null;
  let faceRect = visitData.images[imageIndex] ? visitData.images[imageIndex].faceRect : null;
  
  let thumbnailUrl = useFaceImageUrl(mediaUuidForImage, faceIdForImage);
  

  const isAdmin = props.firebase.auth.currentUser.isAdmin

  //console.log('Visit View is rendering',visit.id);
  return (
    <div>
      <Card fluid color={isComparedTo ? "violet" : "orange"}>
        <Card.Content>
          <Image floated="left" rounded src={thumbnailUrl} style={{ maxHeight: "75px", marginBottom: "0px" }} onError={onThumbnailError} />
          <Card.Header>
            <Label attached='top right'>
              Visit ID
                <CopyToClipboard text={visit.id} onCopy={() => setCopiedVisitId(true)}>
                <Label.Detail>{visit.id} {copiedVisitId && <Icon inverted color="green" name="clipboard check" />}</Label.Detail>
              </CopyToClipboard>
            </Label>
            {visitMoment.format('LLLL')}
          </Card.Header>
          <Card.Meta><h4>At {location.name + ", " + location.region + " "} </h4></Card.Meta>
        </Card.Content>
        <Card.Content>
          <Grid stackable columns={2}>
            <Grid.Column>
              {children &&
                <Grid.Row stretched textAlign='center' >
                  <ThumbnailPanel mediaUuid={visitData.images[imageIndex].mediaUuid} faceRect={faceRect} onError={onThumbnailError} />
                </Grid.Row>
              }
              <Grid.Row>
                <br />
                <VisitMetadataGlance personData={person} idents={visitData.identifications} confidence={{ max: visitData.visitConfidence, avg: visitData.averageConfidence }} />
              </Grid.Row>
            </Grid.Column>
            {!children ?
              <Grid.Column verticalAlign='middle'>
                <Grid.Row stretched textAlign='center' >
                  <ThumbnailPanel mediaUuid={visitData.images[imageIndex].mediaUuid} faceRect={faceRect} maxConfidence={visitData.visitConfidence} onError={onThumbnailError} />
                </Grid.Row>
              </Grid.Column>
              :
              <Grid.Column>
                <Grid.Row stretched >
                  {children}
                </Grid.Row>
              </Grid.Column>
            }

          </Grid>
        </Card.Content>
        <Card.Content extra>
          <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/v/${visit.id}`} onCopy={() => setCopiedLink(true)}>
            <Button icon labelPosition='left' onBlur={() => setCopiedLink(false)}>
              <Icon name={copiedLink ? 'check' : 'chain'} color='purple' />Get link
              </Button>
          </CopyToClipboard>
          <Button icon
            labelPosition='left'
            onClick={() => setIsDetailsModalOpened(true)}>
            <Icon name='list' color='blue' />More details</Button>

          { isAdmin && allowComparison && <Button icon
              labelPosition='left'
              onClick={compareWith && !isComparedTo ? doCompare : setVisitCompared}
              floated="right"
            color={compareWith && !isComparedTo ? "violet" : null}>
            <Icon name='columns' color={compareWith && !isComparedTo ? null : "violet"} />
            {compareWith && !isComparedTo ? "Compare" : isComparedTo ? "Cancel" : "Compare to"}
          </Button>
          }
          {isAdmin &&
            <Button icon
              labelPosition='left'
              floated="right"
              onClick={goToVisitsHistory}>
              <Icon name='history' color='black' />History</Button>
          }
          {isAdmin && <Button icon
            labelPosition='left'
            floated="right"
            loading={mergeWorking}
            disabled={mergeWorking}
            onClick={() => setOpenMergeModal(true)}>
            <Icon name='sign in alternate' color='black' />Merge
              </Button>
          }
          {isAdmin && <Button icon
            labelPosition='left'
            floated="right"
            loading={deleteWorking}
            disabled={deleteWorking}
            onClick={() => setOpenDeleteModal(true)}>
            <Icon name='trash' color='red' />Delete
            </Button>
          }

        </Card.Content>
      </Card>

      <Modal size='large' open={isDetailsModalOpened}>
        <Modal.Header>
          <Header as="h4">
            <Header.Content>
              Showing details of visit from {Moment(visitData.timestamp.toDate()).tz(location.timezone).format('LLLL')}
              <Header.Subheader>in {location.name + ", " + location.region}</Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Label attached='top right'>
            Visit ID: <Label.Detail>{visit.id} </Label.Detail>
          </Label>
          <VisitDetails visit={visit} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="black" onClick={() => setIsDetailsModalOpened(false)}>
            <Icon name='x' /> Close
            </Button>
        </Modal.Actions>
      </Modal>

      {props.deleteVisit && <DeleteModal
        open={openDeleteModal}
        action={() => deleteThisVisit()}
        onCancel={() => setOpenDeleteModal(false)}
        visitDetails={
          {
            visitID: visit.id,
            thumbnailURL: thumbnailUrl,
            datetime: Moment(visitData.timestamp.toDate()).tz(location.timezone).format('LLLL'),
            location: location.name + ", " + location.region + " (" + location.country + ")"
          }
        } />}
      {props.anonMergeVisit && <MergeModal
        open={openMergeModal}
        action={(mergeToUuid) => mergeThisVisit(mergeToUuid)}
        onCancel={() => setOpenMergeModal(false)}
        thumbnailURL={thumbnailUrl}
        uuid={visitData.person.uuid}
        visitID={visit.id}
      />}
    </div>
  );
}

export default compose(
  withFirebase,
  withRouter
)(VisitView);
