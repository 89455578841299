import React, { } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Button, Icon, Table, Header } from 'semantic-ui-react';

const PersonUpdateModal = props => {
    let { uuid, isOpen, trigger, prevPersonData, newPersonData } = props;

    const update = doConfirm => {
        props.onUpdate(doConfirm);
    }


    return (
        <Modal trigger={trigger} open={isOpen}>
            <Modal.Header /*style={{ backgroundColor: '#F2711D', color: 'white' }}*/>Update {uuid}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as='h4'>This action will update {uuid} with new person data and cannot be reversed</Header>
                </Modal.Description>

                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Customer ID</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>
                                Previous Person Data
                                </Table.Cell>
                            <Table.Cell width='10'>
                                <Header as='h4'>
                                    {prevPersonData.name}
                                </Header>
                            </Table.Cell>
                            <Table.Cell width='4' textAlign="center">{prevPersonData.customerId}</Table.Cell>
                            <Table.Cell width='2' textAlign="center">{prevPersonData.role}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                New Person Data
                                </Table.Cell>
                            <Table.Cell width='10'>
                                <Header as='h4'>
                                    {newPersonData.name}
                                </Header>
                            </Table.Cell>
                            <Table.Cell width='4' textAlign="center">{newPersonData.customerId}</Table.Cell>
                            <Table.Cell width='2' textAlign="center">{newPersonData.role}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='grey' onClick={() => update(false)}><Icon name='remove' /> Cancel</Button>
                <Button color='orange' onClick={() => update(true)}><Icon name='user cancel' /> Update</Button>
            </Modal.Actions>
        </Modal>
    );
}


PersonUpdateModal.propTypes = {
    uuid: PropTypes.string.isRequired,      //isOpen: Is open?
    isOpen: PropTypes.bool.isRequired,      //isOpen: Is open?
    trigger: PropTypes.object.isRequired,      //trigger: What triggers the modal to prompt
    prevPersonData: PropTypes.object.isRequired,
    newPersonData: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};


export default PersonUpdateModal;