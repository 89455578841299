import React, { useState } from 'react';
//import { PropTypes } from 'prop-types';
import { Card, Image, Icon, Button, Modal } from 'semantic-ui-react';
import VisitDetails from '../../../Visit/VisitView/visitDetails';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FeedbackTypes } from '../../../../constants/feedback';
import { useFaceImageUrl } from '../../../Images';

const VisitCandidateView = props => {
    const [copiedLink, setCopiedLink] = useState(false);

    const onSelected = () => {
        if (props.visitCandidate) {
            const { data, id } = props.visitCandidate
            let feedbackData = Object.assign({}, data);
            delete feedbackData.identifications;

            var feedbackUpdate = {
                identifiedResult: true,
                visitRole: feedbackData.person.role,
                type: FeedbackTypes.candidate,
                data: {
                    candidateId: id,
                    candidateData: feedbackData,
                }
            };
            props.onSelectCallback(id);
            props.updateFeedback(feedbackUpdate, props.sale.id);

        }
        else {
            props.onSelectCallback('none');
            props.updateFeedback(null, props.sale.id);
        }
    }

    const { visitCandidate, editMode, feedbackEdit, selected } = props;
    const data = visitCandidate && visitCandidate.data;
    const person = data && data.person;

    const strongMatch = !!(visitCandidate && visitCandidate.strongMatch);
    const isSelected = visitCandidate ? selected === visitCandidate.id : selected === 'none' ? true : false;

    const mediaUuidForImage = data && data.images ? data.images[data.images.length - 1].mediaUuid : null;
    const faceIdForImage = data && data.images ? data.images[data.images.length - 1].faceId : null;
    const faceImageUrl = useFaceImageUrl(mediaUuidForImage, faceIdForImage);

    let _onSelectClick = () => null;
    if (!feedbackEdit)
        _onSelectClick = null;
    else if
        (!visitCandidate) _onSelectClick = onSelected;

    return (
        <Card
            style={{ display: 'inline-block', margin: '5px', cursor: feedbackEdit ? 'pointer' : 'default', border: `2px ${isSelected ? '#009358' : strongMatch ? '#F2711C' : feedbackEdit ? 'black' : 'lightgrey'} solid`, width: '154px' }}
            onClick={_onSelectClick}
        >
            {visitCandidate ?
                <>
                    <Card.Content onClick={visitCandidate && feedbackEdit ? onSelected : null}>
                        <Card.Header textAlign="center" style={{ color: strongMatch ? '#F2711C' : 'black' }}><span style={{ float: 'left', marginLeft: '-10px', marginTop: '-7px', color: isSelected ? '#009358' : 'lightgrey' }}><Icon name='check circle outline' /> </span> {data.localDate.split(' ')[1]} {data.localDate.split(' ')[2]}</Card.Header>
                        <Card.Header textAlign="center" style={{ color: strongMatch ? '#F2711C' : 'black' }}>{person.name === 'Unknown' ? <br /> : person.name}</Card.Header>
                    </Card.Content>
                    <Image src={faceImageUrl} wrapped ui={false} onClick={visitCandidate && feedbackEdit ? onSelected : null} style={{ width: '150px' }} />
                    <Card.Content>
                        {editMode &&
                            <Button icon>
                                <Icon name='trash' color='red' />
                            </Button>
                        }
                        <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/v/${visitCandidate.id}`} onCopy={() => setCopiedLink(true)}>
                            <Button icon onBlur={() => setCopiedLink(false)}>
                                <Icon name={copiedLink ? 'check' : 'chain'} color='purple' />
                            </Button>
                        </CopyToClipboard>
                        <Modal trigger={<Button icon><Icon name='info' color='blue' /></Button>}>
                            <Modal.Header>{person.name} | {data.localDate.split(' ')[1]} {data.localDate.split(' ')[2]}</Modal.Header>
                            <Modal.Content scrolling>
                                <VisitDetails visit={visitCandidate} />
                            </Modal.Content>
                        </Modal>
                    </Card.Content>
                </>
                :
                <>
                    <Card.Content style={{ height: '282px' }}>
                        <Card.Header textAlign="center"><span style={{ float: 'left', marginLeft: '-10px', marginTop: '-7px', color: isSelected ? '#009358' : 'lightgrey' }}><Icon name='check circle outline' /> </span>Unknown</Card.Header>
                    </Card.Content>
                </>
            }
        </Card>
    );
}

export default VisitCandidateView;