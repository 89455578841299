import React, { useState, useEffect } from 'react';
import { compose } from "recompose";
import { PropTypes } from 'prop-types';

import { Icon, Table } from 'semantic-ui-react';
import { TableFilter } from '../../UI';

import { withLoading } from '../../Lists';
import EnrollmentQueueItem from './enrollmentQueueItem';

import { EnrollmentStatusName, EnrollmentLabelColor, EnrollmentStatus } from "../../../constants/enrollment"

const EnrollmentQueueList = props => {

    let { queue } = props;
    let displayQueue = queue;

    const [statusSortingOptions, setStatusSortingOptions] = useState([]);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        const statusSet = [...new Set(props.queue.map(enroll => enroll.data.status))];
        let statusOptions = statusSet.map(status => ({
            key: status,
            text: EnrollmentStatusName[status],
            value: status,
            icon: { color: EnrollmentLabelColor(status), name: "circle" },
            default: status !== EnrollmentStatus.success,
        }));
        setStatusSortingOptions(statusOptions);

    }, [props.queue]);

    const onFilter = (filter, field) => {
        let filtersCopy = filters;
        filtersCopy[field] = filter;
        setFilters(filtersCopy);
    }


    if (filters.status) {
        displayQueue = displayQueue.filter(enroll => filters.status.includes(enroll.data.status));
    }
    const defaultFilters = [EnrollmentStatus.error, EnrollmentStatus.pending, EnrollmentStatus.queued, EnrollmentStatus.running, EnrollmentStatus.canceled];
    return (
        <Table celled structured unstackable color="orange">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center"><Icon name="user circle outline" /></Table.HeaderCell>
                    <Table.HeaderCell>Visit Data</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">CID</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Conf.</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Visit</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Action <TableFilter field='status' options={statusSortingOptions} onFilter={filter => onFilter(filter, 'status')} default={defaultFilters} /></Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {displayQueue.map(enrollmentObject => (
                    <EnrollmentQueueItem key={enrollmentObject.id}
                        data={enrollmentObject.data}
                        enrollID={enrollmentObject.id}
                        onEdit={props.onEnrollEdited}
                        onEnrollment={props.onEnrollment}
                        onEnrollmentReset={props.onEnrollmentReset}
                        onEnrollmentRevert={props.onEnrollmentRevert}
                        editNote={props.editNote}
                        onEnrollmentCancel={props.onEnrollmentCancel}
                        onMerge={props.onMerge}
                        isProcessing={props.isProcessing}
                        compareItemWithLatestVisit={props.compareItemWithLatestVisit} />
                ))}
            </Table.Body>

        </Table>
    );
}

EnrollmentQueueList.propTypes = {
    queue: PropTypes.array.isRequired,               //queue: The queue of items to enroll
    editNote: PropTypes.func.isRequired,             //editNote: Trigger to edit note
    compareItemWithLatestVisit: PropTypes.func.isRequired,      //compareItemWithLatestVisit: Allows to compare an item with a person's latest visit
};


const loadingCondition = props =>
    props.isLoading;

export default compose(
    withLoading(loadingCondition)
)(EnrollmentQueueList);