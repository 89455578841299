import React, { useState, useEffect } from 'react';
import { Image, Icon, Button, Modal } from "semantic-ui-react";

const DeleteModal = props => {

    const [open, setOpen] = useState(props.open);
    //const [working, setWorking] = useState(false);
    //const [duo, setDuo] = useState(props.allowUserDeletion);

    const actionStyle = {
        color: 'red',
    };

    useEffect(()=>{
        setOpen(props.open);
    })

    const deleteOnClick = () => {
        setOpen(false);
        props.action();
    }

    const cancelOnClick = () => {
        props.onCancel();
        setOpen(false);
    }

    return (
        <Modal size="mini" open={open}>
            <Modal.Header><Icon name='trash' color='red' /> Are you sure?</Modal.Header>
            <Modal.Content>
                <h4>Are you sure you want to <strong style={actionStyle}>delete</strong> this visit from {props.visitDetails.datetime} at {props.visitDetails.location}?</h4>
                <Image src={props.visitDetails.thumbnailURL} rounded fluid />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={cancelOnClick}>Cancel</Button>
                <Button negative icon='trash' labelPosition='left' content='Delete' onClick={deleteOnClick} />
            </Modal.Actions>
        </Modal>
    );
}


export default DeleteModal;