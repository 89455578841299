import Firebase from '../Firebase';
import { Document } from 'adf-builder';


const JiraBaseURL = 'https://api.atlassian.com/ex/jira/66c5585f-4886-4bb5-8d7f-b66488bfbc6b'
const JiraCustomerIssueTypeId = '10014'
const JiraCustomerPoejectId = '10005'
const JiraCustomerPoejectAbv = 'CCI'

const CustomField = {
    visitId: 'customfield_10030',
    personId: 'customfield_10031',
    locationId: 'customfield_10032',
    customer: 'customfield_10034',
    customerContact: 'customfield_10033',
    visitDate: 'customfield_10035',
}

const createJiraIssue = async (summary, fields) => {

    try {
        let { data: tokenData } = await Firebase.functions.httpsCallable('jira-getToken')();
        let requestHeaders = new Headers();
        requestHeaders.append("Content-Type", "application/json");
        requestHeaders.append("Authorization", `Bearer ${tokenData.token}`);

        const description = new Document();
        description.paragraph()
            .text(fields.description);
        description.rule();
        description.paragraph()
            .text(`Enroll Id: ${fields.enrollId}`);
        description.paragraph()
            .link('Visits Link', fields.visitLink);
        description.paragraph()
            .link('Previous Visits', fields.personHistory);
        description.paragraph()
            .text('~ Created via the Caazam webapp');


        let data = {
            //update: ,
            fields: {
                issuetype: {
                    id: JiraCustomerIssueTypeId,
                },
                project: {
                    id: JiraCustomerPoejectId,
                },
                summary: summary,
                description: description.toJSON(),
            }
        }

        data.fields[CustomField.customer] = { value: process.env.REACT_APP_JIRA_PROJECT };
        data.fields[CustomField.customerContact] = fields.customerContact;
        data.fields[CustomField.visitDate] = fields.visitDate;
        data.fields[CustomField.locationId] = fields.locationId;
        data.fields[CustomField.visitId] = fields.visitId;
        data.fields[CustomField.personId] = fields.personId;

        let requestOptions = {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        let result = await fetch(`${JiraBaseURL}/rest/api/3/issue`, requestOptions);
        let jsonResult = await result.json();
        return {
            id: jsonResult.id,
            key: jsonResult.key,
            selfLink: `https://caazam.atlassian.net/projects/${JiraCustomerPoejectAbv}/issues/${jsonResult.key}`,
        }
    } catch (error) {
        console.error('JIRA create error',error)
        throw error;
    }
}

export default createJiraIssue;