import React from 'react';
import { Item, Icon, Label } from "semantic-ui-react";
import { useImageUrl } from '../../../Images';

const QueueItem = (props) => {
    let { operation, operationendVisit } = props;
    const thumbnailImage = useImageUrl(operationendVisit.mediaUuid, 'thumb');

    return (
        <Item>
            <Item.Image floated="left" size="small" src={thumbnailImage} />
            <Item.Content verticalAlign="middle">
                <Item.Header as='a'>{operation.operationCode.charAt(0).toUpperCase() + operation.operationCode.slice(1) + " operation"}</Item.Header>
                <Item.Description><h4>Performed on: {operation.visitId}</h4></Item.Description>
                <Item.Extra>
                    <Label color={operation.status === "success" ? "green" : (operation.status === "running" ? "orange" : "red")}>
                        <Icon loading={operation.status === "running"} name={operation.status === "success" ? "check" : (operation.status === "running" ? "spinner" : "exclamation triangle")} />
                        {operation.status === "success" ? "Completed" : (operation.status === "running" ? "In progress" : "Error")}
                    </Label>
                </Item.Extra>
            </Item.Content>
        </Item>
    );
}

export default QueueItem;