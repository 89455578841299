import React, { Component } from 'react';
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";

import { Segment, Message, Loader, Grid, Icon } from 'semantic-ui-react';
import { CustomerView } from "../UI";
import CustomerSearch from "./SearchCustomer";
import "./customerProfile.css";

import queryString from 'query-string';
import CustomerVisitsView from './customerVisitsView';
import VisitFeedItem from '../VisitsFeed/visitsFeedItem';


class CustomerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            isLoading: true,
            isError: false,
            errorMsg: null,
            isShowingProfile: false,
        }

        this.config = {
            showCRMNoDataWarning: props.firebase.remoteConfigStore.showCRMNoDataWarning,
            showSizeMetrics: !!process.env.REACT_APP_SHOW_SIZE_METRICS,
        }

    }

    componentDidMount() {
        const queryParam = queryString.parse(this.props.location.search);
        this.loadProfile(queryParam);
    }

    loadProfile = key => {
        const API = this.props.firebase.caazamAPI;
        var param = key.uuid ? { uuid: key.uuid } : key.cid ? { customerId: key.cid } : null;
        this.setState({ isLoading: true, isError: null });

        if (param) {
            this.setState({ isShowingProfile: true });
            API.getPersonProfile(param).then(profileData => {                
                if (profileData.lastPurchase) profileData.lastPurchase.date = new Date(profileData.lastPurchase.date);
                if (profileData.lastVisit) profileData.lastVisit.date = new Date(profileData.lastVisit.date);
                this.setState({ profile: profileData, isLoading: false, isError: null });
            }).catch(error => {
                console.error(error);
                this.setState({ isLoading: false, isError: true, errorMsg: error.message });
            });
        }
    }

    render() {
        const { profile, isLoading, isError, errorMsg, isShowingProfile } = this.state;
        return (
            <Segment basic padded>
                <Loader active={isShowingProfile && isLoading} inline='centered'>Loading customer profile</Loader>
                {isShowingProfile && !isLoading && profile &&
                    <>
                        <p onClick={() => this.setState({ isShowingProfile: false })} style={{ cursor: "pointer" }}><Icon name="arrow left" /> <u>Back to Search</u></p>
                        <ProfileView config={this.config} {...profile} />
                    </>
                }
                {!isShowingProfile && <CustomerSearch onResultSelected={this.loadProfile} />}
                {isError && !isLoading && <ErrorMessage errorMsg={errorMsg} />}

            </Segment>
        );
    }
}

const ProfileView = (props) => (
    <>
        <VisitFeedItem {...props} />
        {props.person.uuid ? <CustomerVisitsView uuid={props.person.uuid} /> : null}
    </>
)

const ErrorMessage = props => (
    <Message negative>
        <Message.Header>
            OOPS! There was an error{" "}
            <span role="img" aria-label="sorry face">😕</span>
        </Message.Header>
        <Message.Content>
            {props.errorMsg}
        </Message.Content>
    </Message>
)

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(CustomerProfile);