import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';

import "./index.css";

import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import ReportVisitsTable from "./reportVisitsTable";

import { Message, Segment, Loader } from "semantic-ui-react";
import OperationsQueue from "./OperationsQueue";
import { FeedbackTypes } from "../../../constants/feedback";

import { ReportStatus } from '../../../constants/reports';
import { PersonRoles } from '../../../constants/roles';
import { EnrollmentMergeReason } from '../../../constants/enrollment';

import ReportBar from '../common/reportHeaderFooter';
import StatusChangeModal from '../common/changeStatusModal';
import FeedbackView from '../common/FeedbackView';
import { ErrorMessage } from "../common/reportListCommon";

import MissedVisits from "../MissedVisits";
import { matchSalesCandidatesToVisits } from "../common/matchCandidates";

const applyReportData = (data) => ({
  reportData: data,
  canEdit: data.status === ReportStatus.new || data.status === ReportStatus.complete,
  canEditFeedback: data.status !== ReportStatus.archived,
});

const applyVisitData = (visits, candidates) => prevState => ({
  visits: visits,
  candidates: candidates,
  isError: false,
  isLoading: false
});

const applySetError = error => prevState => ({
  isError: true,
  isLoading: false,
  errorMsg: error
});

const applyNotesData = data => prevState => {
  return {
    notesData: data,
  }
}

class VisitReport extends Component {
  constructor(props) {
    super(props);

    let { reportId, reportData } = props;
    this.reportId = reportId;

    this.transactions = {};

    this.state = {
      reportData: null,
      visits: [],
      candidates: {},
      deletedVisits: null,
      feedbackData: {},
      notesData: {},

      isLoading: true,
      isError: false,
      errorMsg: "",

      showOpsQueue: false,
      isFbsShown: false, //Fbs = feedback summary
      statusChangeModalOpen: false,

      canEdit: false,
      canEditFeedback: true,

      filter: {
        roles: {}
      },

      comapreWith: null,

    };

    this.state = Object.assign(this.state, applyReportData(reportData));
    this.missedVisits = null;

    this.state.filter.roles[PersonRoles.anonymous] = true;
    this.state.filter.roles[PersonRoles.default] = true;
    this.state.filter.roles[PersonRoles.employee] = false;

    this.dictOperations = {};
    this.runningOpsNum = 0;
    this.preventingCounter = 0;

    this.visitToTransactionMatchingConfig = this.props.firebase.remoteConfigStore.visitToTransactionMatching;
  }

  componentDidMount() {
    const { firebase, reportData, authUser } = this.props;
    this.listenToContent = firebase.reports.listenToContent(reportData.scope, this.onVisitsUpdate.bind(this), this.onTransactionsUpdate.bind(this), this.onListenError.bind(this));
    this.listenToFeedback = firebase.reports.visitFeedback.listenToFeedback(this.reportId, this.onFeedbackUpdate.bind(this));
    this.listenToNotes = firebase.reports.visitNotes.listenAll(this.reportId, this.onNoteChanged);
    if (authUser.isAdmin) {
      this.listenToDeletedVisits = firebase.visits.listenToDeletedVisits(reportData.scope, this.onDeletedVisit);
    }

    try {
      this.preloadPendings(this.reportId, firebase.auth.currentUser.uid, this.listenToOperation);
    }
    catch (error) {
      console.error(error);
      this.setState(applySetError(error.message));
    }
  }

  componentWillUnmount() {
    //// Detach the listeners ////
    this.listenToContent && this.listenToContent();
    this.listenToFeedback && this.listenToFeedback();

    this.listenToDeletedVisits && this.listenToDeletedVisits();
    this.listenToNotes && this.listenToNotes();
    /////////////////////////////
  }

  onVisitsUpdate(updates) {
    let visitsToAdd = [];
    let visitsToModify = [];
    let visitsToDelete = [];
    let candidatesUpdate = {}

    updates.forEach(update => {
      switch (update.type) {
        case 'added':
          let newVisit = { id: update.id, data: update.data }
          visitsToAdd.push(newVisit);
          candidatesUpdate[newVisit.id] = matchSalesCandidatesToVisits(newVisit, Object.values(this.transactions), this.visitToTransactionMatchingConfig);
          break;
        case 'modified':
          let updateVisit = { id: update.id, data: update.data }
          visitsToModify.push(updateVisit);
          candidatesUpdate[updateVisit.id] = matchSalesCandidatesToVisits(updateVisit, Object.values(this.transactions), this.visitToTransactionMatchingConfig);
          break;
        case 'removed':
          let deleteVisitId = update.id;
          visitsToDelete.push(deleteVisitId);
          candidatesUpdate[deleteVisitId] = null;
          break;
        default:
          break;
      }
    })

    var newVisits = this.state.visits;
    visitsToDelete.forEach(visitId => {
      let index = newVisits.findIndex(t => t.id === visitId);
      if (index >= 0)
        newVisits = update(newVisits, { $splice: [[index, 1]] });
    });
    visitsToModify.forEach(visit => {
      let index = newVisits.findIndex(t => t.id === visit.id);
      if (index >= 0)
        newVisits = update(newVisits, { [index]: { $set: visit } })
      else
        newVisits = update(newVisits, { $push: [visit] });
    });
    newVisits = update(newVisits, { $push: visitsToAdd });

    var newCandidates = this.state.candidates;
    Object.keys(candidatesUpdate).forEach(visitId => {
      newCandidates = update(newCandidates, { [visitId]: { $set: candidatesUpdate[visitId] } });
    })

    this.setState(applyVisitData(newVisits, newCandidates));
  }

  onTransactionsUpdate(updates) {
    const allowAnonSalesAsCandidates = this.props.firebase.remoteConfigStore.allowAnonSalesAsCandidates;

    updates.forEach(update => {

      if (!allowAnonSalesAsCandidates && update.data.isAnonymous) {
        return; //skip this transaction if not allowed
      }

      switch (update.type) {
        case 'added':
          let newTrans = { id: update.id, data: update.data }
          this.transactions[newTrans.id] = (newTrans);
          break;
        case 'modified':
          let updateTrans = { id: update.id, data: update.data }
          this.transactions[updateTrans.id] = (updateTrans);
          break;
        case 'removed':
          let deleteTransId = update.id;
          if (this.transactions[deleteTransId]) {
            delete this.transactions[deleteTransId];
          }
          break;
        default:
          break;
      }
    });

    let newCandidates = this.state.visits.reduce((agg, visit) => {
      agg[visit.id] = matchSalesCandidatesToVisits(visit, Object.values(this.transactions), this.visitToTransactionMatchingConfig);
      return agg;
    }, {});

    var candidatesUpdate = this.state.candidates;
    Object.keys(this.state.candidates).forEach(visitId => {
      // NOTE: we will only update candidates for a transaciton if a visit was removed or added but not if its data has been modified
      if (this.state.candidates[visitId].length !== newCandidates[visitId].length) {
        candidatesUpdate = update(candidatesUpdate, { [visitId]: { $set: newCandidates[visitId] } });
      }
    })
    this.setState({ candidates: candidatesUpdate });
  }

  onFeedbackUpdate(querySnapshot) {
    querySnapshot.docChanges().forEach(change => {
      if (change.type === 'added') {
        let feedbackData = update(this.state.feedbackData, { [change.doc.id]: { $set: change.doc.data() } });
        this.setState({ feedbackData });
      }
      if (change.type === 'modified') {
        let feedbackData = update(this.state.feedbackData, { [change.doc.id]: { $set: change.doc.data() } });
        this.setState({ feedbackData });

      }
      if (change.type === 'removed') {
        let feedbackData = update(this.state.feedbackData, { $unset: [change.doc.id] });
        this.setState({ feedbackData });
      }
    });
  }

  onListenError(error) {
    console.error(error);
    this.setState(applySetError(error.message));
  }

  getTransactionData(transId) {
    return this.transactions[transId];
  }

  onPreventAction = (prevent) => {
    const reason = "Some visits require your attention. Fix them before proceeding."
    if (prevent) {
      this.setState(applySetError(reason))
      this.preventingCounter++;
    }
    else {
      if (this.preventingCounter - 1 > 0) {
        this.preventingCounter--;
      }
      else {
        this.setState({ isError: false });
        this.preventingCounter = 0;
      }
    }
  }

  onDeletedVisit = deletedVisits => {
    //this.props.firebase.....
    this.setState({ deletedVisits: deletedVisits });
  }

  onNoteChanged = notes => {
    this.setState(applyNotesData(notes));
  }


  listenToOperation = (operationID, operationedVisitData) => {
    this.operationListener = this.props.firebase.caazamAPI.listenToOperations(
      operationID,
      docData => {//On operation change
        this.dictOperations[operationID] = { operationData: docData, opedVisitData: operationedVisitData };
        this.setState({ showOpsQueue: true });
        if (docData.status === "success") {
          this.operationListener(); //detach listener
        }
      },
      error => {//On error
        console.error("There was an error in the operations listener and it says: ", error);
      })
  }

  deleteVisit = (visitId, visitData) => {
    this.props.firebase.caazamAPI.deleteVisit(visitId, { reportId: this.reportId })
      .then(responseData => {
        this.listenToOperation(responseData.operationId, visitData);
      }).catch(error => {
        throw error;
      });
  }

  mergeAnon = (visitId, uuid, mergeToUuid) => {
    return this.props.firebase.caazamAPI.anonMerge(visitId, uuid, mergeToUuid, this.reportId, EnrollmentMergeReason.anonymous, false /* onlyVisit */)
      .then(() => {
        return;
      })
      .catch(error => {
        throw error;
      });
  }

  setVisitCompared = visitID => {
    this.setState({ comapreWith: visitID });
  }

  preloadPendings = (reportID, requestedBy, listener) => {
    let query = this.props.firebase.caazamAPI.getFirestoreRef()
      .collection('visitOps')
      .where("reportId", "==", reportID)
      .where("status", "==", "running")
      .where("requestedBy", "==", requestedBy);
    return query.get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          listener(doc.id);
        });
      })
      .catch(error => {
        throw error;
      });
  }

  onQueueClose = () => {
    this.setState({ showOpsQueue: false });
  }

  onRoleFilterToggle = (role) => {
    let filter = this.state.filter;
    filter.roles[role] = !filter.roles[role]
    this.setState({ filter: filter });
  }

  handleStatusChange = (reportId, newStatus, inputValue, onComplete) => {

    const currentStatus = this.state.reportData.status;
    let options = {};
    if (currentStatus === ReportStatus.open) {
      options.submittedBy = inputValue || null;
    }
    if (this.missedVisits) {
      options.missedVisits = this.missedVisits.filter(missed => !!missed.isInStore)
    }

    this.props.statusUpdateDelegate(reportId, newStatus, options, onComplete);
  }

  toggleFeedbackSummary = () => {
    if (!this.state.isFbsShown)
      this.setState({ isFbsShown: true });
    else
      this.setState({ isFbsShown: false });
  }

  handleStatusChangeModalOpen = () => {
    this.setState({ statusChangeModalOpen: true })
  }

  handleStatusChangeModalClosed = () => {
    this.setState({ statusChangeModalOpen: false })
  }

  getFilteredFeedbackMap = fbMap => {
    let newMap = {};
    for (let k of Object.keys(fbMap)) {
      let feedback = fbMap[k];
      let isFalsePositive = feedback.visitRole !== PersonRoles.anonymous && !feedback.identifiedResult;
      let isEnrollment = feedback.visitRole === PersonRoles.anonymous && feedback.type !== FeedbackTypes.unknown;
      if (isFalsePositive || isEnrollment)
        newMap[k] = feedback;
    }
    return newMap;
  }

  render() {
    const {
      visits,
      candidates,
      feedbackData,
      notesData,
      isLoading,
      isError,
      reportData,
      canEdit,
      canEditFeedback,
      isFbsShown,
      filter,
      deletedVisits,
      comapreWith,
    } = this.state;

    const ReportBarCtrl = (props) => {
      return (
        <ReportBar.Controls
          isFeedbackDisabled={Object.keys(feedbackData).length === 0 && (feedbackData).constructor === Object}
          reportData={reportData}
          filter={filter}
          onFilterToggle={this.onRoleFilterToggle}
          filterType='roles'
          handleChangeStatusModal={this.handleStatusChangeModalOpen}
          toggleFeedbackSummary={this.toggleFeedbackSummary}
          isError={isError} />

      )
    }

    return (
      <div>
        {
          reportData && !isLoading &&
          <ReportBar>
            <ReportBar.Metadata reportData={reportData} contentCount={visits.length} />
            <ReportBarCtrl />
          </ReportBar>
        }
        {isError && (<ErrorMessage errorMsg={this.state.errorMsg} />)}
        {(visits.length === 0 && !isLoading && !isError) && <NoResults />}
        {isFbsShown && <FeedbackView reportId={this.reportId} reportData={reportData} feedbackData={feedbackData} notesData={notesData} content={visits} deletedVisits={deletedVisits} />}
        {isLoading && <Loader active>Loading visits...</Loader>}
        <div className="ops-queue">{this.state.showOpsQueue ? <OperationsQueue operationsToLstn={this.dictOperations} remainingOps={this.runningOpsNum} onClose={this.onQueueClose} /> : null}</div>

        <ReportVisitsTable
          visits={visits}
          transactionCandidates={candidates}
          transactionDataDelegate={(transId) => this.getTransactionData(transId)}
          isLoading={isLoading}
          onVisitDelete={this.deleteVisit}
          onAnonMerge={this.mergeAnon}
          editMode={canEdit}
          feedbackEdit={canEditFeedback}
          filter={filter}
          reportId={this.reportId}
          feedbackData={feedbackData}
          notesData={notesData}
          onPreventAction={this.onPreventAction}
          compareWith={comapreWith}
          setVisitCompared={this.setVisitCompared}
        />
        {
          reportData && !isLoading &&
          <ReportBar>
            <ReportBarCtrl />
          </ReportBar>
        }
        {reportData &&
          <StatusChangeModal
            open={this.state.statusChangeModalOpen}
            reportId={this.reportId}
            data={reportData}
            handleStatusChange={this.handleStatusChange}
            handleCancel={this.handleStatusChangeModalClosed}>
            {reportData.status === ReportStatus.open &&
              <MissedVisits
                feedbackData={feedbackData}
                transactions={this.transactions}
                candidates={candidates}
                missedVisits={this.missedVisits}
                setMissedVisits={missedVisits => this.missedVisits = missedVisits }
                reportData={reportData}
              />}
          </StatusChangeModal>
        }
      </div>
    );
  }
}

const NoResults = props => {
  return (
    <Segment basic>
      <Message info>
        <Message.Header>
          No results for this report
        </Message.Header>
        <Message.Content>
          No visits recorded in this location on this date
        </Message.Content>
      </Message>
    </Segment>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withAuthorization(condition),
  withFirebase
)(VisitReport);
