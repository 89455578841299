import app from 'firebase/app';
import 'firebase/firestore';

import { EnrollmentStatus } from '../../constants/enrollment';
const COLL_NAME_ENROLLMENT = "enrollments";

class EnrollmentData {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_ENROLLMENT);
    }

    fetchActiveQueue = (onData, onError) => {
        const activeStatus = [EnrollmentStatus.pending, EnrollmentStatus.queued, EnrollmentStatus.running, EnrollmentStatus.error];
        let query = this.db.where('status', 'in', activeStatus).orderBy("metadata.created", "desc");
        return query.onSnapshot(querySnapshot => {
            let entries = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            onData(entries);
        },
            error => {
                onError(error);
            });
    }

    countContextQueue = (contextId, onData, onError) => {
        let query = this.db.where('contextId', '==', contextId).where('status', 'in', [EnrollmentStatus.queued, EnrollmentStatus.running]);
        return query.onSnapshot(querySnapshot => {
            let entries = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            onData(entries);
        }, error => {
            onError(error);
        });
    }

    countPendingEnrolls = (onNumber, onError, customStatusArray, contextId) => {
        let statusArray = customStatusArray ? customStatusArray : [EnrollmentStatus.pending];
        
        let query = this.db;
        if (contextId) query = query.where("contextId", "==", contextId);        
        query = query.where('status', 'in', statusArray);

        return query.onSnapshot(querySnapshot => {
            onNumber(querySnapshot.size);
        },
            error => {
                onError(error);
            });
    }

    enrollContextListner = (onEnrollContext, onError) => {
        let uuid = app.auth().currentUser.uid;
        if (!uuid) {
            onError(new Error(`can't listen to enroll context for invlaid user`));
            return;
        }

        return app.firestore().collection('users').doc(uuid).collection('appData').doc('enrollContext').onSnapshot(snap => {
            onEnrollContext(snap.data());
        }, (error) => onError(error));
    }

    updateEnrollmentWithJiraIssue = (enrollId, jiraIssue) => {

        return app.functions().httpsCallable('enroll-updateEnrollJiraIssue')({ enrollId, jiraIssue });
    }
}

export default EnrollmentData;