import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Divider, Icon, Segment, Image, Label } from 'semantic-ui-react';
import { openFullpageImage, useImageUrl } from '../../Images';

class ImageCompare extends Component {
    static propTypes = {
        imageA: PropTypes.object,                 //imageA: First visit's image
        imageB: PropTypes.object,                 //imageB: Second visit's image
    };

    render() {
        let { imageA, imageB } = this.props;
        return (
            <Grid.Row centered>
                {imageA ? <ImageComp data={imageA} /> : <NoData />}
                <Grid.Column width="1">
                    <Divider vertical><Icon name="image" /></Divider>
                </Grid.Column>
                {imageB ? <ImageComp data={imageB} /> : <NoData />}
            </Grid.Row>
        );
    }
}

const gridInfoRowStyle = { paddingTop: "0px" };

const ImageComp = props => {
    let imageData = props.data;
    const url = useImageUrl(imageData.mediaUuid);
    return (
        <Grid.Column>
            <Segment>
                <Grid centered>
                    <Grid.Row columns="1">
                        <Grid.Column>
                            <Image fluid rounded src={url} onClick={e => openFullpageImage(imageData.mediaUuid)} style={{ cursor: 'alias' }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" style={gridInfoRowStyle} >
                        <Label>{parseFloat(Math.round(imageData.confidence * 10000) / 100).toFixed(2) + "%"}</Label>
                        <Label>{imageData.faceRect.width}px</Label>
                        <Label>{imageData.faceAtt.gender}</Label>
                        <Label color={imageData.faceAtt.age < 13 ? 'red' : null} >age {imageData.faceAtt.age}</Label>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Grid.Column>
    )
}

const NoData = props => (
    <Grid.Column>
        {/* NO DATA */}
    </Grid.Column>
)

export default ImageCompare;