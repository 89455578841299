import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Image, Header, Label, Icon, Button, Modal, Form, Dropdown, TextArea, Divider } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip'
import Moment from "moment-timezone";
import { Link } from 'react-router-dom';


import { compose } from "recompose";
import { withFirebase } from "../../Firebase";

import EnrollmentPrecheckDetails from './enrollmentPrecheckDetails';
import { Note } from "../../UI"

import { EnrollmentStatus, EnrollmentType, EnrollmentStatusName, EnrollmentLabelColor, EnrollmentCancelReason, EnrollmentMergeReason } from "../../../constants/enrollment";
import { PersonRoles } from '../../../constants/roles';
import EnrollmentMergeModal from './Modals/mergeModal';
import PersonUpdateModal from './Modals/updateModal';
import JiraModal from './enrollmentJira';
import UUID from '../../UI/uuid';
import { useImageUrl } from '../../Images';

const EnrollmentQueueItem = (props) => {

    let { data, enrollID } = props;
    let { type = EnrollmentType.enroll,
        customerId,
        uuid,
        name,
        role,
        status,
        visitId,
        notes,
        preEnrollCheck: checks,
        reportSubmittedBy,
        visitData: visit,
        jiraIssue } = data;

    const [showPrecheckDetails, setShowPrecheckDetails] = useState(false);
    const [isPersonEditModalOpen, setPersonEditModalOpen] = useState(false);
    const [isEnrollWorking, setEnrollWorking] = useState(false);
    const [enrollError, setEnrollError] = useState(null);

    const [isDismissModalOpen, setDismissModalOpen] = useState(false);
    const [isMergeModalOpen, setMergeModalOpen] = useState(false);
    const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);

    const [isJiraIssueModalOpen, setJiraIssueModalOpen] = useState(false);

    const [dismissReason, setDismissReason] = useState(null);
    const [dismissText, setDismissText] = useState(null);

    const thumbnailUrl = useImageUrl(data.visitData.images[0].mediaUuid, 'thumb');

    useEffect(()=>{
        if (props.data.error) {
            if (props.data.error.error)  {// caazam custom error
                setEnrollError(`${props.data.error.error.message}: ${props.data.error.error.reason}`)
            } else {
                setEnrollError(props.data.error);
            }
        }
    }, [props.data.error])

    const onEnrollClick = () => {
        setEnrollWorking(true);
        setEnrollError(null);
        props.onEnrollment(enrollID).then(() => {
            setEnrollWorking(false);
        }).catch(error => {
            setEnrollWorking(false);
            setEnrollError(error.message);
        })
    }

    const onResetClick = () => {
        setEnrollWorking(true);
        setEnrollError(null);
        props.onEnrollmentReset(enrollID).then(() => {
            setEnrollWorking(false);
        }).catch(error => {
            setEnrollWorking(false);
            setEnrollError(error.message);
        })
    }

    const onRevertClick = () => {
        setEnrollWorking(true);
        setEnrollError(null);
        props.onEnrollmentRevert(enrollID).then(() => {
            setEnrollWorking(false);
        }).catch(error => {
            setEnrollWorking(false);
            setEnrollError(error.message);
        })

    }

    const getPrecheckIcons = (checks = {}) => {

        const nullDataIcon = "question circle";
        const nullDataTip = "Not available yet";
        const nullDtatColor = "grey"

        const validDataIcon = "check circle";
        const validDataTip = "Valid";
        const validDataColor = "green";

        const warnDataIcon = "exclamation circle";
        const warnDataTip = "Missing";
        const warnDataColor = "orange";

        const errorDataIcon = "ban";
        const errorDataTip = "Cannot run checks on this entry";
        const errorDataColor = "red";

        const invalidDataIcon = "exclamation circle";
        const invalidDataTip = "Click for more details";
        const invalidDataHandler = () => setShowPrecheckDetails(!showPrecheckDetails);
        const invalidDataColor = "red";

        let cIcon = {
            name: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            customerId: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            confidence: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
            visit: {
                icon: nullDataIcon,
                tip: nullDataTip,
                onClick: null,
                color: nullDtatColor,
            },
        };
        if (checks.checkStatus) {
            cIcon = {
                name: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                customerId: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                confidence: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
                visit: {
                    icon: invalidDataIcon,
                    tip: invalidDataTip,
                    onClick: invalidDataHandler,
                    color: invalidDataColor,
                },
            };
            let cStat = checks.checkStatus;
            if (cStat.name) {
                cIcon.name = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
            if (cStat.customerId) {
                cIcon.customerId = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            } else if (!checks.customerId) {
                cIcon.customerId = {
                    icon: warnDataIcon,
                    tip: warnDataTip,
                    onClick: null,
                    color: warnDataColor,
                };
            }
            if (cStat.confidence) {
                cIcon.confidence = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
            if (cStat.visit) {
                cIcon.visit = {
                    icon: validDataIcon,
                    tip: validDataTip,
                    onClick: null,
                    color: validDataColor,
                };
            }
        }
        else if (checks.error) {
            cIcon = {
                name: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                customerId: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                confidence: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
                visit: {
                    icon: errorDataIcon,
                    tip: errorDataTip,
                    onClick: null,
                    color: errorDataColor,
                },
            };
        }
        return cIcon;
    }

    const onNoteEdited = note => {
        props.editNote(enrollID, note);
    }

    const onDismissReasonSelected = (e, { value }) => {
        setDismissReason(value);
    }

    const onFreeTextDismissReasonChanged = text => {
        setDismissText(text === "" ? null : text);
    }

    const doDismissEnroll = confirmation => {
        if (confirmation) {
            setEnrollError(null);
            props.onEnrollmentCancel(enrollID, dismissReason, dismissText).then(() => {
                setEnrollWorking(false);
            }).catch(error => {
                setEnrollWorking(false);
                setEnrollError(error.message);
            });
            setEnrollWorking(true);
            setDismissModalOpen(false);
        }
        else {
            setDismissModalOpen(false);
        }
    }

    const doMerge = (confirmation, targetUuid, reason) => {
        if (confirmation) {
            //            ** Enroll **
            setEnrollError(null);
            props.onMerge(enrollID, targetUuid, reason).then(() => {
                setEnrollWorking(false);
            }).catch(error => {
                setEnrollWorking(false);
                setEnrollError(error.message);
            });
            setEnrollWorking(true);
            setMergeModalOpen(false);
        }
        else {
            setMergeModalOpen(false);
        }
    }

    const doPersonUpdate = async (confirmation) => {
        setUpdateModalOpen(false);
        if (confirmation) {
            setEnrollError(null);
            setEnrollWorking(true);
            try {
                await props.firebase.caazamAPI.updatePerson(enrollID);
                setEnrollWorking(false);
            } catch (error) {
                setEnrollWorking(false);
                setEnrollError(error.message);
            }
        }
    }
    let checkIcons = getPrecheckIcons(checks);
    let { localDate: date, location, visitConfidence } = visit;    
    let suggestionsForMerge = [];
    let suggestionForUpdate = null;
    let forceMerge = false;
    let mergeReason = type === EnrollmentType.enroll ? EnrollmentMergeReason.false_negative : EnrollmentMergeReason.false_positive;
    
    // build merge suggestions from pre enroll checks
    if (checks) {
        if (checks.name) {
            if (checks.customerId) {
                if (checks.customerId.uuid !== checks.name.uuid) {
                    suggestionsForMerge = suggestionsForMerge.concat(checks.name);
                }
            }
            else {
                suggestionsForMerge = suggestionsForMerge.concat(checks.name);
            }
        }
        if (checks.customerId) {
            suggestionsForMerge = suggestionsForMerge.concat(checks.customerId);
        }
        if (checks.person) {
            suggestionForUpdate = checks.person;
        }
    }

    const editMode = status === EnrollmentStatus.pending;
    const isStatusError = status === EnrollmentStatus.error;
    const isChecksError = checks && checks.error;
    const isInError = isStatusError && isChecksError;

    const actionsDisabled = !checks || isChecksError || isEnrollWorking || props.isProcessing;
    const typeLabelColor = type === EnrollmentType.enroll ? 'green' : type === EnrollmentType.false_positive ? 'red' : null;
    const typeLabelText = type === EnrollmentType.enroll ? 'Enroll' : type === EnrollmentType.false_positive ? 'False Positive' : 'Anonymous';
    const reportLink = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/reports/${data.reportId}`;

    return (
        <>
            <Table.Row warning={type === EnrollmentType.false_positive && !isInError} negative={isInError}>
                <Table.Cell width="2">
                    <Link to={'/v/' + visitId} target="_blank">
                        <Image rounded fluid src={thumbnailUrl} data-tip="Open this visit in new tab" label={{ attached: 'top left', content: typeLabelText, color: typeLabelColor }}/>
                    </Link>
                </Table.Cell>
                <Table.Cell width="6">
                    <Header as="h3">
                        {name}
                        <Button compact basic color={jiraIssue && 'blue'} size="mini" floated="right" onClick={jiraIssue ? () => window.open(jiraIssue, '_blank') : () => setJiraIssueModalOpen(true)}>{jiraIssue ? 'View on Jira' : 'Open Jira issue'}</Button>
                        <JiraModal
                            open={isJiraIssueModalOpen}
                            enrollId={enrollID}
                            enrollData={data}
                            notes={notes}
                            setModalClosed={() => setJiraIssueModalOpen(false)}
                        />
                        {editMode && <PersonEditModal
                            enrollId={props.enrollID}
                            isPersonEditModalOpen={isPersonEditModalOpen}
                            setPersonEditModalOpen={setPersonEditModalOpen}
                            customerId={customerId}
                            role={role}
                            name={name}
                            onEdit={props.onEdit}
                        />}
                        <br />
                        <Header.Subheader>
                            <b>UUID: </b><UUID value={uuid} /><br />
                            <b>Customer ID: </b>{customerId ? customerId : <cite>Unavailable</cite>}<br />
                            <b>Visit ID: </b> {visitId}<br />
                            { data.reportId && <span><a href={reportLink} target='_blank'><b>View Report</b></a> <br /></span>}
                            {reportSubmittedBy && <div><b>Submitted by: </b>{reportSubmittedBy}</div>}
                            {isChecksError && <Header as="h5" color="red">{checks.error.reason || checks.error}</Header>}
                            <ReactTooltip effect="solid" delayHide={100} />
                        </Header.Subheader>
                    </Header>
                    <Label.Group>
                        <Label color={role !== PersonRoles.anonymous ? 'orange' : 'grey'}>{role}</Label>
                        <Label>{location.name}</Label>
                        <Label>{Moment(visit.timestamp.toDate()).tz(location.timezone).format('l')}</Label>
                        <Label color={visitConfidence === 1.0 ? 'green' : null}>{(visitConfidence * 100).toFixed(2) + '%'}</Label>
                    </Label.Group>
                    {(notes || (!notes && editMode)) && <Note compact notes={notes} editMode={editMode} onEdit={onNoteEdited} />}
                </Table.Cell>
                <Table.Cell textAlign="center" width="1">
                    <Icon inverted color={checkIcons.name.color} size="large" name={checkIcons.name.icon} onClick={checkIcons.name.onClick} data-tip={checkIcons.name.tip} />
                </Table.Cell>
                <Table.Cell textAlign="center" width="1">
                    <Icon inverted color={checkIcons.customerId.color} size="large" name={checkIcons.customerId.icon} onClick={checkIcons.customerId.onClick} data-tip={checkIcons.customerId.tip} />
                </Table.Cell>
                <Table.Cell textAlign="center" width="1">
                    <Icon inverted color={checkIcons.confidence.color} size="large" name={checkIcons.confidence.icon} onClick={checkIcons.confidence.onClick} data-tip={checkIcons.confidence.tip} />
                </Table.Cell>
                <Table.Cell textAlign="center" width="1">
                    <Icon inverted color={checkIcons.visit.color} size="large" name={checkIcons.visit.icon} onClick={checkIcons.visit.onClick} data-tip={checkIcons.visit.tip} />
                </Table.Cell>
                <Table.Cell textAlign="center" width="2">
                    {/* Enrollment actions by status */}
                    {status === EnrollmentStatus.pending &&
                        <Button.Group color="orange">
                            {type === EnrollmentType.enroll &&
                            <Button loading={isEnrollWorking} disabled={actionsDisabled} onClick={() => onEnrollClick()}>Enroll</Button>}
                        {type === EnrollmentType.false_positive &&
                            <EnrollmentMergeModal trigger={<Button loading={isEnrollWorking} disabled={actionsDisabled} onClick={() => setMergeModalOpen(true)}>Merge</Button>} uuid={uuid} mergeSuggestions={suggestionsForMerge} force={forceMerge} reason={mergeReason} onMerge={doMerge} isOpen={isMergeModalOpen} />}
                        <Dropdown item floating direction='right' className='button icon' disabled={actionsDisabled} closeOnChange closeOnBlur>
                            <Dropdown.Menu>
                                {type === EnrollmentType.enroll && <EnrollmentMergeModal
                                    trigger={<Dropdown.Item onClick={() => setMergeModalOpen(true)}>Merge</Dropdown.Item>}
                                    uuid={uuid}
                                    mergeSuggestions={suggestionsForMerge}
                                    force={forceMerge}
                                    reason={mergeReason}
                                    onMerge={doMerge}
                                    isOpen={isMergeModalOpen}
                                />}
                                {type === EnrollmentType.false_positive && suggestionForUpdate && <PersonUpdateModal
                                    trigger={<Dropdown.Item onClick={() => setUpdateModalOpen(true)}>Update</Dropdown.Item>}
                                    uuid={uuid}
                                    prevPersonData={suggestionForUpdate.data}
                                    newPersonData={{ name, role, customerId }}
                                    onUpdate={doPersonUpdate}
                                    isOpen={isUpdateModalOpen}
                                />}
                                {type === EnrollmentType.false_positive && <Dropdown.Item onClick={() => null}>Make Anonymous</Dropdown.Item>}
                                {type !== EnrollmentType.anonymous && <Dropdown.Divider />}
                                <DismissEnrollmentModal trigger={<Dropdown.Item onClick={() => setDismissModalOpen(true)}>Dismiss</Dropdown.Item>} dismissReason={dismissReason} dismissText={dismissText} onReasonSelected={onDismissReasonSelected} onFreeTextReasonChanged={onFreeTextDismissReasonChanged} isOpen={isDismissModalOpen} onAction={doDismissEnroll} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button.Group>
                    }                    
                    {status === EnrollmentStatus.error &&
                        <Button.Group color="red">
                            <Button loading={isEnrollWorking} disabled={isEnrollWorking || props.isProcessing} onClick={() => setDismissModalOpen(true)}>Dismiss</Button>
                            <DismissEnrollmentModal isOpen={isDismissModalOpen} dismissReason={dismissReason} dismissText={dismissText} onReasonSelected={onDismissReasonSelected} onFreeTextReasonChanged={onFreeTextDismissReasonChanged} onAction={doDismissEnroll} />
                            <Dropdown item floating direction='right' className='button icon' disabled={props.isProcessing}>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => onResetClick()}>Reset</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Button.Group>
                    }
                    {![EnrollmentStatus.error, EnrollmentStatus.pending].includes(status) && <Label circular basic color={EnrollmentLabelColor(status)}>{EnrollmentStatusName[status]}</Label>}
                    {status === EnrollmentStatus.queued && !props.executeContextId && !props.isProcessing &&
                        <>
                            <Divider />
                            <Button size='mini' compact basic loading={isEnrollWorking} disabled={isEnrollWorking} onClick={() => onRevertClick()}>Revert</Button>
                        </>
                    }
                    {enrollError && <Header as="h5" color="red">{enrollError}</Header>}
                </Table.Cell>
            </Table.Row>
            {showPrecheckDetails &&
                <Table.Row>
                    <Table.Cell colSpan="1" textAlign="right"></Table.Cell>
                    <Table.Cell colSpan="6">
                        <EnrollmentPrecheckDetails details={checks} enrollVisitID={visitId} compareItemWithLatestVisit={props.compareItemWithLatestVisit} />
                    </Table.Cell>
                </Table.Row>
            }
        </>

    );
}

EnrollmentQueueItem.propTypes = {
    data: PropTypes.object.isRequired,      //data: the enrollable visit data
    enrollID: PropTypes.string.isRequired,      //enrollID: ID of this enrollment item
    editNote: PropTypes.func.isRequired,      //editNote: Trigger to edit note
    compareItemWithLatestVisit: PropTypes.func.isRequired,      //compareItemWithLatestVisit: Allows to  compare item to person's latest visit
};

const PersonEditModal = props => {
    let { enrollId, isPersonEditModalOpen, setPersonEditModalOpen } = props;
    let [customerId, setCustomerId] = useState(props.customerId);
    let [role, setRole] = useState(props.role);
    let [name, setName] = useState(props.name);
    let [isWorking, setWorking] = useState(false);
    let [editError, setEditError] = useState(null);

    const onEditResult = (isOk, result) => {
        setWorking(false);
        if (isOk) {
            setEditError(null);
            setPersonEditModalOpen(false);
        }
        else {
            setRole(props.role);
            setName(props.name);
            setCustomerId(props.customerId)
            setEditError(result.message);
        }
    }

    const onEdit = () => {
        let editName = name === "" ? props.name : name;
        let editCustomerId = customerId === "" ? props.customerId : customerId;
        props.onEdit(enrollId, editName, editCustomerId, role, onEditResult);
        setWorking(true);
    }

    const onEditCancel = () => {
        setWorking(false);
        setEditError(null);
        setPersonEditModalOpen(false);
    }

    return (
        <Modal open={isPersonEditModalOpen} centered={false} size="tiny" trigger={<Button compact basic size="mini" floated="right" onClick={() => setPersonEditModalOpen(true)}>Edit</Button>}>
            <Modal.Header>Edit</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input label='Name' placeholder={name} fluid onChange={e => setName(e.target.value)} />
                        <Form.Input label='Customer ID' placeholder={customerId !== null ? customerId : null} fluid onChange={e => setCustomerId(e.target.value)} icon={<Icon name='remove' inverted circular link onClick={() => setCustomerId(null)} />} />
                    </Form.Group>
                    <Form.Group inline>
                        <label>Role</label>
                        <Form.Radio
                            label='Customer'
                            value='default'
                            checked={role === 'default'}
                            onChange={(e, { value }) => setRole(value)}
                        />
                        <Form.Radio
                            label='Employee'
                            value='employee'
                            checked={role === 'employee'}
                            onChange={(e, { value }) => setRole(value)}
                        />
                    </Form.Group>
                </Form>
                <br />
                <Modal.Description>
                    {
                        editError && <Header color="red" as="h5">{editError}</Header>
                    }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" basic disabled={isWorking} onClick={onEditCancel}>Cancel</Button>
                <Button color="orange" loading={isWorking} disabled={isWorking} onClick={() => onEdit(name, customerId, role)}>Done</Button>
            </Modal.Actions>
        </Modal>
    )
}

const DismissEnrollmentModal = props => {
    const { isOpen, trigger, dismissReason, dismissText, onReasonSelected, onFreeTextReasonChanged, onAction } = props;
    return (
        <Modal trigger={trigger} open={isOpen}>
            <Modal.Header /*style={{ backgroundColor: '#F2711D', color: 'white' }}*/>Enrollment cancellation</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as='h4'>This action will remove this enrollment from the queue and cannot be reversed</Header>
                    <Form>
                        <Form.Group inline><label>Please state the reason you wish to cancel this enrollment:</label></Form.Group>
                        <Form.Group>
                            <Form.Radio
                                label='False identification'
                                value={EnrollmentCancelReason.false}
                                checked={dismissReason === EnrollmentCancelReason.false}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Radio
                                label='Duplicate'
                                value={EnrollmentCancelReason.duplicate}
                                checked={dismissReason === EnrollmentCancelReason.duplicate}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Radio
                                label='Requires manual enrollment'
                                value={EnrollmentCancelReason.manual}
                                checked={dismissReason === EnrollmentCancelReason.manual}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Radio
                                label='No-Op'
                                value={EnrollmentCancelReason.no_op}
                                checked={dismissReason === EnrollmentCancelReason.no_op}
                                onChange={onReasonSelected}
                            />
                        </Form.Group>
                        <Form.Field control={TextArea} onChange={e => onFreeTextReasonChanged(e.target.value)} label='Detailed reason' placeholder='Please write more details about the reason you chose above' />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='grey' onClick={() => onAction(false)}><Icon name='remove' /> Cancel</Button>
                <Button color='orange' disabled={dismissText === null || dismissReason === null} onClick={() => onAction(true)}><Icon name='user cancel' /> Remove from queue</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default compose(
    withFirebase
)(EnrollmentQueueItem);