import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Image, Placeholder } from 'semantic-ui-react';
import "./imageView.css";

const ViewModifiers = {
    hidden: "hidden",
    landscape: "thumbnail-landscape",
    portraite: "thumbnail-portrait",
}

const CaazamImageView = (props) => {
    const { src, faceRect, onError, ...semanticUiProps } = props;

    const [imgViewClass, setImgViewClass] = useState(ViewModifiers.hidden);

    const onImageLoaded = ({ target: img }) => {
        if (img.naturalWidth > img.naturalHeight)
            setImgViewClass(ViewModifiers.landscape);
        else
            setImgViewClass(ViewModifiers.portraite);
    }

    const calculateBoxDimensions = faceRect => {
        if (faceRect) {
            let x = faceRect.left * 100 - faceRect.width * 25;
            if (x < 0) x = 0;
            let y = faceRect.top * 100 - faceRect.height * 75;
            if (y < 0) y = 0;
            let w = faceRect.width * 150;
            if ((x + w) > 100) w = 100 - x;
            let h = faceRect.height * 210;
            if ((y + h) > 100) h = 100 - y;
            return {
                left: x + "%",
                top: y + "%",
                width: w + "%",
                height: h + "%"
            }
        }
        else {
            return {};
        }
    }

    let boxDimensions = calculateBoxDimensions(faceRect);

    return (
        <div className="image-container" {...semanticUiProps}>
            {imgViewClass !== ViewModifiers.hidden && <div className={faceRect && "box"} style={boxDimensions}></div>}
            {imgViewClass === ViewModifiers.hidden &&
                <Placeholder fluid style={{ height: 300, width: 500, borderRadius: "5px" }}>
                    <Placeholder.Image />
                </Placeholder>
            }
            <Image
                className={imgViewClass}
                src={src}
                rounded
                centered
                fluid
                onError={onError}
                onLoad={onImageLoaded}
            />
        </div>
    );
}

CaazamImageView.propTypes = {
    src: PropTypes.string.isRequired,      //src: Image source --> imageSource
    faceRect: PropTypes.object,            //faceRect: The dimensions of a face in the image
    onError: PropTypes.func,               //onError: Errors handler
};

export default CaazamImageView;