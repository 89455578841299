import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Button, Icon, Table, Header, Form, Radio, Input, Dropdown, Label } from 'semantic-ui-react';
import UUID from '../../../UI/uuid';

import { EnrollmentMergeReason } from '../../../../constants/enrollment'

const MergeReasonOptions = [
    {
        key: 'none',
        text: '',
        value: null,
    },
    {
        key: EnrollmentMergeReason.false_negative,
        text: 'False Negative',
        value: EnrollmentMergeReason.false_negative,
    },
    {
        key: EnrollmentMergeReason.false_positive,
        text: 'False Positive',
        value: EnrollmentMergeReason.false_positive,
    },
]

const EnrollmentMergeModal = props => {
    let { uuid, isOpen, trigger, mergeSuggestions, force, reason: reasonInit } = props;

    const [selected, setSelected] = useState(force ? mergeSuggestions[0].uuid : null);
    const [otherUuid, setOtherUuid] = useState('');
    const [reason, setReason] = useState(reasonInit);

    const onRadioSelect = (e, data) => {
        setSelected(data.value);
    }

    const merge = doConfirm => {
        if (doConfirm) {
            let targetUuid = selected;
            if (selected === 'other')
                targetUuid = otherUuid;
            props.onMerge(true, targetUuid, reason);
        }
        else {
            props.onMerge(false);
        }
    }


    return (
        <Modal trigger={trigger} open={isOpen}>
            <Modal.Header /*style={{ backgroundColor: '#F2711D', color: 'white' }}*/>Merge {uuid}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as='h4'>This action will merge {uuid} with another one you choose and cannot be reversed</Header>
                </Modal.Description>

                <Table compact celled definition>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Customer ID</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Role</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {mergeSuggestions && mergeSuggestions.map(person => (
                            <Table.Row key={person.uuid}>
                                <Table.Cell collapsing>
                                    <Radio
                                        name={'selectMerge'}
                                        value={person.uuid}
                                        checked={selected === person.uuid}
                                        onChange={onRadioSelect}
                                        disabled={force}
                                    />
                                </Table.Cell>
                                <Table.Cell width='10'>
                                    <Header as='h4'>
                                        {person.data.name}
                                        <Header.Subheader><UUID value={person.uuid} /></Header.Subheader>
                                    </Header>
                                </Table.Cell>
                                <Table.Cell width='4' textAlign="center">{person.data.customerId}</Table.Cell>
                                <Table.Cell width='2' textAlign="center">{person.data.role}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>

                    {!force && <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Radio
                                    name={'selectMerge'}
                                    value={'other'}
                                    checked={selected === 'other'}
                                    onChange={onRadioSelect}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='1'>
                                <Form>
                                    <Form.Field>
                                        <label>Or merge with this UUID:</label>
                                        <Input placeholder='Enter UUID here' value={otherUuid} onChange={e => setOtherUuid(e.target.value)} disabled={selected !== 'other'} error={otherUuid === null || otherUuid === ""} />
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Footer>}
                </Table>
                <Dropdown selection options={MergeReasonOptions} value={reason} onChange={(e, { value }) => setReason(value)} placeholder='Select Reason' disabled={force}/>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='grey' onClick={() => merge(false)}><Icon name='remove' /> Cancel</Button>
                <Button color='orange' disabled={selected === null || (selected === 'other' && (otherUuid === null || otherUuid === ""))} onClick={() => merge(true)}><Icon name='user cancel' /> Merge</Button>
            </Modal.Actions>
        </Modal>
    );
}


EnrollmentMergeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,      //isOpen: Is open?
    trigger: PropTypes.object.isRequired,      //trigger: What triggers the modal to prompt
    mergeSuggestions: PropTypes.array.isRequired,      //mergeSuggestions: Suggestions for the merge
    onMerge: PropTypes.func.isRequired,      //onMerge: What happens when the user does merge
    force: PropTypes.bool               // force the first merge option only
};


export default EnrollmentMergeModal;