import React, { useState } from 'react';
import { Grid, Modal, Image, Message } from 'semantic-ui-react';

import { RichCard, RichGrid, RichImage, RichIcon, RichParagraph, RichHeader } from '../CaazamUI';
import FeedMetrics from './VisitFeedComponents/feedMetrics';
import AllPurchasedItems from './allPurchasedItems';

import RecentItem from './VisitFeedComponents/recentItem';
import { useFaceImageUrl  } from '../Images';


const VisitFeedItem = (props) => {

    const { owner, images, location, config } = props;
    
    const [isProductModalOpen, setProductModalState] = useState(false);
    const mediaUuidForImage = images && images[0] ? images[0].mediaUuid : null;
    const faceIdForImage = images && images[0] ? images[0].faceId : null;
    const faceImageUrl = useFaceImageUrl(mediaUuidForImage, faceIdForImage);

    let ownerStr = owner ? `Owner: ${owner}` : 'Owner is not assigned';
    let nameComponent = props.detailsLink ? <a href={props.detailsLink} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>{props.person.name} <RichIcon icon="open_in_new" size='small' /></a> : <>{props.person.name}</>;

    ////////////////////////////////////////////
    const { maxItems = 6 } = props;

    let noteText = props.userDescription ? props.userDescription : '';
    noteText += props.userDescription && props.userNotes ? ' ' + props.userNotes : props.userNotes ? props.userNotes : '';
    const showCRMNoDataWarning = (!props.totalSpend || !props.person.customerId) && config.showCRMNoDataWarning;
    return (
        <Grid.Column width={16}>
            <RichCard sideBySide={props.productHistory || props.productRecommendations}>
                <RichCard.MainPanel>
                    <RichCard.Metadata><p>{props.moment ? props.moment.format('l, LT') : <br />}</p></RichCard.Metadata>
                    <RichCard.Content image={faceImageUrl}>
                        <RichHeader textAlign="center">
                            {nameComponent}
                            <RichHeader.Subheader>
                                <>{location && location.name}{location && <br />}{ownerStr}</>
                            </RichHeader.Subheader>
                        </RichHeader>
                        <Divider topMessage message={showCRMNoDataWarning && 'CRM info not available for this customer visit'} />
                        <FeedMetrics {...props} />
                        {(props.userDescription || props.userNotes) &&
                            <>
                                <Divider />
                                <RichIcon interactive icon='pin' value='Notes' />
                            </>
                        }
                        <LimitedDescription description={noteText} />

                    </RichCard.Content>
                </RichCard.MainPanel>
                {(props.productHistory || props.productRecommendations) &&
                    <RichCard.ExtendedPanel>
                        <RichCard.Content>
                            {props.productHistory && props.productHistory.length > 0 &&
                                <RichGrid alignment="center" gridSize="3" rowCount={2}>
                                    <RichGrid.Header>Recent Purchases</RichGrid.Header>
                                    <RichGrid.Layout>
                                        {
                                            props.productHistory.length > maxItems ?
                                                <>
                                                    {props.productHistory.slice(0, maxItems - 1).map((product, index) =>
                                                        <RecentItem product={product} person={props.person} key={index} />
                                                    )}
                                                    <RichGrid.Box>
                                                        <RichImage src={process.env.PUBLIC_URL + '/show_all.png'} size='x-small' inline onClick={() => setProductModalState(true)} ></RichImage>
                                                        {isProductModalOpen && <AllPurchasedItems person={props.person} onModalClose={setProductModalState} />}
                                                    </RichGrid.Box>
                                                </>
                                                :
                                                props.productHistory.slice(0, maxItems).map((product, index) =>
                                                    <RecentItem product={product} person={props.person} key={index} />
                                                )

                                        }
                                    </RichGrid.Layout>
                                </RichGrid>
                            }
                            <br />
                            <br />
                            {props.productRecommendations && props.productRecommendations.length > 0 &&
                                <RichGrid alignment="center" gridSize="3" rowCount={2}>
                                    <RichGrid.Header>She Might Also Like</RichGrid.Header>
                                    <RichGrid.Layout>
                                        {
                                            props.productRecommendations.slice(0, maxItems).filter(product => !!product.name).map((product, index) =>
                                                <RecommendedItem product={product} key={index} />
                                            )
                                        }
                                    </RichGrid.Layout>
                                </RichGrid>
                            }
                        </RichCard.Content>
                    </RichCard.ExtendedPanel>
                }
            </RichCard>
        </Grid.Column>
    );
}

const RecommendedItem = props => {
    const [isRecommModalOpen, setRecommModalOpenState] = useState(false);
    const { product } = props;
    let productName = product.name;

    if (productName.split(' ').length > 5) { //Improve logic
        productName = productName.split(' ');
        productName.splice(4)
        productName[productName.length - 1] = productName[productName.length - 1].substring(0, 1)
        productName = productName.join(' ');
        productName += '...';
    }
    return (
        <RichGrid.Box>
            <RichImage src={product.imageUrl} inline softload placeholderDirection="portrait" size='x-small' onClick={() => setRecommModalOpenState(true)}>
                <RichImage.Content>
                    <RichImage.Description>
                        {productName}
                        <PriceDisplay price={product.price} final_price={product.final_price} />
                    </RichImage.Description>
                </RichImage.Content>
            </RichImage>
            <Modal
                open={isRecommModalOpen}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={() => setRecommModalOpenState(false)}>
                <Modal.Content image>
                    <Image src={product.imageUrl} size='medium' wrapped />
                    <Modal.Description>
                        <RichHeader>{product.name}</RichHeader>
                        <br />
                        <div>Price: <PriceDisplay price={product.price} final_price={product.final_price} /></div>
                        <br />
                        {product.link && <p><a href={product.link} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View on ruti.com</a></p>}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </RichGrid.Box>
    );
}

const PriceDisplay = props => {
    const { price, final_price } = props;
    const isOnSale = !!final_price && price !== final_price;
    if (!isOnSale) {
        return <span style={{ fontSize: '12px', display: 'inline-block', fontWeight: '600' }}>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</span>
    } else {
        return (
            <>
                <s style={{ fontSize: '12px', display: 'inline-block', fontWeight: '600' }}>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</s>
                {' '}
                <span style={{ fontSize: '12px', display: 'inline-block', fontWeight: '600' }}>{final_price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</span>
            </>
        )
    }
}

const LimitedDescription = props => {
    const [isLimited, setLimitedState] = useState(true);
    let limitCondition = true;
    let displayText = props.description;
    if (isLimited) {
        if (displayText.split(' ').length > 40) { //Improve logic
            displayText = displayText.split(' ');
            displayText.splice(39)
            displayText = displayText.join(' ');
            displayText += '...';
        }
        else {
            limitCondition = false;
        }
    }
    else {
        displayText = props.description;
    }


    let readString = isLimited ? 'Read More' : 'Read Less';

    const readStyle = { color: '#3088F7', fontSize: '16px', fontWeight: '600', cursor: 'pointer', userSelect: 'none' };

    return (
        <RichParagraph style={{ overflowY: 'auto', height: '125px' }}>
            {displayText} {limitCondition && <span style={readStyle} onClick={() => setLimitedState(!isLimited)}>{readString}</span>}
        </RichParagraph>
    );
}

const Divider = props => (
    <>
        {props.message && props.topMessage ? <Message warning content={props.message} style={{ textAlign: 'center' }}/> : <br />}
        <hr />
        {props.message && !props.topMessage ? <Message warning content={props.message} style={{ textAlign: 'center' }}/> : <br />}
    </>
)


export default VisitFeedItem;