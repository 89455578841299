import React from 'react';
import { compose } from "recompose";
import { Link } from 'react-router-dom';
import { Button, Segment, Header, Table, Icon } from "semantic-ui-react";

import { withFirebase } from "../Firebase";

import { withAdminAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingEnrolls: "Loading",
      lightspeedStatus: null,
      jiraStatus: null,
      jiraAuthURL: null,
    }

    this.unsubscribeFromEnrollmentQueueCounter = null;
  }

  componentDidMount() {
    const firebase = this.props.firebase;
    this.unsubscribeFromEnrollmentQueueCounter = firebase.enrollment.countPendingEnrolls(this.onCounterUpdated, this.onDatabaseError);
    firebase.rtdb.ref('integrations/lightspeed/token').on('value', snapshot => {
      this.setState({ lightspeedStatus: snapshot.exists() });
    })
    
    var getToken = firebase.functions.httpsCallable('jira-getToken');
    var getAuthUrl = firebase.functions.httpsCallable('jira-jiraOAuthGetURL');
    getToken().then(({ data }) => {
      this.setState({ jiraStatus: !!data.token });
    }).catch(error => {
      this.setState({ jiraStatus: false });
      console.error('getToken:', error);
    });
    getAuthUrl().then(({ data }) => {
      this.setState({ jiraAuthURL: data.authUrl });
    }).catch(error => {
      console.error('getAuthUrl:', error);
    });
  }

  onCounterUpdated = count => {
    this.setState({ pendingEnrolls: count });
  }

  onDatabaseError = error => {
    this.setState({ pendingEnrolls: error.message });
  }

  componentWillUnmount() {
    this.unsubscribeFromEnrollmentQueueCounter();
  }

  render() {
    let { pendingEnrolls, lightspeedStatus, jiraStatus, jiraAuthURL } = this.state;

    return (
      <div>
        <h1>Admin</h1>
        <AdminMenu pending={pendingEnrolls} />
        <SystemConfigMenu />
        <Segment>
          <Table celled basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width="13">
                  <Header as="h3">
                    <span>
                      JIRA Authorization
                      {jiraStatus !== null && <Icon name='circle' color={jiraStatus ? 'green' : 'red'} />}
                    </span>
                    {jiraStatus === false && <Header sub color='red'>JIRA integration is not active and requires admin authorization</Header>}
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button loading={jiraStatus === null} disabled={!jiraAuthURL} fluid icon href={jiraAuthURL} target="_blank" rel="noopener noreferrer" labelPosition='right'><Icon name="chevron circle right" /><b>Authenticate</b></Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        {process.env.REACT_APP_LIGHTSPEED && <Segment>
          <Table celled basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width="13">
                  <Header as="h3">
                    <span>
                      Lightspeed Authorization
                      {lightspeedStatus !== null && <Icon name='circle' color={lightspeedStatus ? 'green' : 'red'} />}
                    </span>
                    {lightspeedStatus === false && <Header sub color='red'>Lightspeed integration is not active and requires admin authorization</Header>}
                  </Header>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button loading={lightspeedStatus === null} fluid icon href={process.env.REACT_APP_CAAZAM_REST_HOST + '/integrations/lightspeed/oauth2/init'} target="_blank" rel="noopener noreferrer" labelPosition='right'><Icon name="chevron circle right" /><b>Authenticate</b></Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>}
      </div>
    );
  }
}


const AdminMenu = props => {
  let { pending } = props;
  let flag = pending > 0 ? `${pending} items pending` : 'View';
  let color = pending > 0 ? "orange" : null;
  return (
    <Segment>
      <Table celled basic="very">
        <Table.Body>
          <Table.Row>
            <Table.Cell width="13">
              <Header as="h3">Enrollment Queue</Header>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button fluid icon color={color} as={Link} to={ROUTES.ENROLLMENT} labelPosition='right'><Icon name="chevron circle right" /><b>{flag}</b></Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="13">
              <Header as="h3">Media Browser</Header>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button fluid icon as={Link} to={ROUTES.MEDIA_BROWSER} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="13">
              <Header as="h3">Visit Browser</Header>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button fluid icon as={Link} to={ROUTES.VISITS_BROWSER} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width="13">
              <Header as="h3">Missed Visits</Header>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button fluid icon as={Link} to={ROUTES.MISSED_VISITS} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
            </Table.Cell>
          </Table.Row>
          {process.env.REACT_APP_CAAZAM_BETA_RECOMMENDATIONS &&
            <Table.Row>
              <Table.Cell width="13">
                <Header as="h3">Beta Product Recommendations</Header>
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Button fluid icon as={Link} to={ROUTES.BETA_RECOMMENDATIONS} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
              </Table.Cell>
            </Table.Row>}
        </Table.Body>
      </Table>
    </Segment>
  );
}

const SystemConfigMenu = props => (
  <Segment>
    <Table celled basic='very'>
      <Table.Body>
        <Table.Row>
          <Table.Cell width="13">
            <Header as="h3">System Status</Header>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button fluid icon as={Link} to={ROUTES.SYSTEM_STATUS} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width="13">
            <Header as="h3">Remote Config Manager</Header>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button fluid icon as={Link} to={ROUTES.REMOTE_CONFIG_MANAGER} labelPosition='right'><Icon name="chevron circle right" /><b>View</b></Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </Segment>
)

export default compose(
  withAdminAuthorization,
  withFirebase
)(Admin);
