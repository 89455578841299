import React from 'react';
import { Item, Label, Table } from 'semantic-ui-react';
import { PersonRoles } from '../../../constants/roles';
import Moment from 'moment-timezone';
import UUID from '../../UI/uuid';

import { CaazamImageView, useImageUrl, openFullpageImage } from '../../Images';

const IdentificationDetails = (props) => {
    const { identification, visitData } = props;
    const imageUrl = useImageUrl(identification.mediaUuid, 'web');
    let idDate = identification.timestamp.toDate();

    return (
        <>
            <Item.Content><CaazamImageView src={imageUrl} faceRect={identification.faceRectNorm} onClick={() => openFullpageImage(identification.mediaUuid)} style={{ cursor: "alias" }} /></Item.Content>
            <Item.Content>
                <Item.Header>
                    <Label color={identification.role === PersonRoles.default ? 'orange' : null}>{identification.role}</Label>
                    <Label >{parseFloat(Math.round(identification.confidence * 10000) / 100).toFixed(2) + "%"}</Label>
                    <Label >{identification.faceRect.width}px</Label>
                    <Label >{identification.faceAtt.gender}</Label>
                    <Label color={identification.faceAtt.age < 13 ? 'red' : null} >age {identification.faceAtt.age}</Label>
                </Item.Header>
                <Item.Description>
                    <Table attached='bottom' color='orange'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Capture Time</Table.Cell>
                                <Table.Cell>{idDate.toISOString()} ({Moment(idDate).tz(visitData.location.timezone).format('l LT')}) </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Media UUID</Table.Cell>
                                <Table.Cell>{identification.mediaUuid}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Face ID</Table.Cell>
                                <Table.Cell>{identification.faceId}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Person UUID</Table.Cell>
                                <Table.Cell><UUID value={identification.uuid} /></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Item.Description>
            </Item.Content>
        </>
    )
}

const VisitDetails = (props) => {
    let visit = props.visit;

    return (
        <Item.Group divided>
            {visit.data.identifications.map(identification => (
                <Item key={identification.faceId}>
                    <IdentificationDetails identification={identification} visitData={visit.data} />
            </Item>
            ))}
        </Item.Group>
    );
}

export default VisitDetails;
