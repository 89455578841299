import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Firebase from '../../Firebase'
import { Header, Button, Modal, Form, TextArea, Card, Grid } from 'semantic-ui-react';
import Moment from "moment-timezone";
import createJiraIssue from '../../Jira';

const JiraModal = (props) => {
    const [jiraIssueDescription, setJiraIssueDescription] = useState(null);
    const [isJiraWorking, setJiraWorking] = useState(false);

    const { open, enrollId, enrollData, setModalClosed } = props;
    const  { uuid, name, visitId, reportSubmittedBy, visitData } = enrollData;
    let { localDate, timestamp, location } = visitData;

    useEffect(()=>{
        if (props.notes && props.notes.text !== '') {
            setJiraIssueDescription(props.notes.text);
        }
    }, [props.notes])

    const openNewJiraIssue = async () => {
        setJiraWorking(true);
        let fields = {
            description: jiraIssueDescription,
            visitId: visitId,
            personId: uuid,
            locationId: location.id,
            customerContact: reportSubmittedBy,
            visitDate: Moment(timestamp.toDate()).tz(location.timezone).format(),
            enrollId: enrollId,
            visitLink: `${window.location.protocol}//${window.location.hostname}/v/${visitId}`,
            personHistory: `${window.location.protocol}//${window.location.hostname}/visitsHistory?uuid=${uuid}`,
        };
        try {
            let jiraIssue = await createJiraIssue(`${name} - ${location.name} - ${localDate}`, fields);
            await Firebase.enrollment.updateEnrollmentWithJiraIssue(enrollId, jiraIssue.selfLink);
            setJiraWorking(false);
            setModalClosed();
        } catch (error) {
            setJiraWorking(false);
            console.error('Failed to create JIRA issue', error);
        };
    }

    return (
        <Modal open={open}>
            <Modal.Header>Open new issue on Jira</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Card fluid>
                        <Card.Content header={`${name}, ${location.name}, ${localDate}`} />
                        <Card.Content>
                            <Grid>
                                <Grid.Column width='10'>
                                    <Header size='small'>Describe the issue</Header>
                                    <Form>
                                        <TextArea placeholder='Describe the issue' value={jiraIssueDescription} onChange={e => setJiraIssueDescription(e.target.value)} />
                                    </Form>
                                </Grid.Column>
                                <Grid.Column width='6'>
                                    <Grid.Row style={{ marginBottom: '6px' }}>
                                        <Header size='tiny'>
                                            Visit ID
                                            <Header.Subheader>{visitId}</Header.Subheader>
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{ marginBottom: '6px' }}>
                                        <Header size='tiny'>
                                            Visit Date
                                            <Header.Subheader>{localDate}</Header.Subheader>
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{ marginBottom: '6px' }}>
                                        <Header size='tiny'>
                                            Person ID
                                            <Header.Subheader>{uuid}</Header.Subheader>
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{ marginBottom: '6px' }}>
                                        <Header size='tiny'>
                                            Location ID
                                            <Header.Subheader>{location.id}</Header.Subheader>
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row style={{ marginBottom: '6px' }}>
                                        <Header size='tiny'>
                                            Customer Contact
                                            <Header.Subheader>{reportSubmittedBy}</Header.Subheader>
                                        </Header>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid>
                        </Card.Content>
                        <Card.Content extra>Preview</Card.Content>
                    </Card>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={setModalClosed}>Cancel</Button>
                <Button color='orange' onClick={() => openNewJiraIssue()} loading={isJiraWorking}>Create issue on Jira</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default JiraModal;