import React  from 'react';
import { Modal } from 'semantic-ui-react';

import { CaazamImageView, useImageUrl } from "../../Images";

const ThumbnailPanel = (props) => {
    const imageUrl = useImageUrl(props.mediaUuid, 'web');

    //If cannot fetch thumbnail show default error image
    const addDefaultSrc = (ev) => {
        if (props.onError) {
            if (!props.onError()) {
                ev.target.src = process.env.PUBLIC_URL + '/image_not_found_404.png'
            }
        } else {
            ev.target.src = process.env.PUBLIC_URL + '/image_not_found_404.png';
        }
    }

    return (
        <div>
            {imageUrl && <Modal
                trigger={<CaazamImageView src={imageUrl} faceRect={props.faceRect} confidence={props.maxConfidence} onError={addDefaultSrc} style={{ cursor: "zoom-in" }} />}
                size='large'
                closeIcon
            >
                <Modal.Content image>
                    <CaazamImageView src={imageUrl} onError={addDefaultSrc} />
                </Modal.Content>
            </Modal>}
        </div>
    );
}

export default ThumbnailPanel;