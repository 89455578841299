import React, { useEffect } from 'react';
import { compose } from "recompose";

import ReactGA from 'react-ga';
import { PropTypes } from 'prop-types';
import { withRouter } from "react-router-dom";
import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";

const trackPage = location => {
    let page = location.pathname + location.search;
    let pathnameComp = location.pathname.split('/');
    switch (pathnameComp[1]) {
        case ROUTES.REPORTS.substr(1):
            if (pathnameComp[2])
                page = ROUTES.REPORTS + '/details';
            else
                page = ROUTES.REPORTS;
            console.log(page);
            break;
        default:
            break;
    }

    ReactGA.set({ page: page });
    ReactGA.pageview(page);
};

const GAListener = ({ children, trackingId, history, firebase }) => {

    useEffect(() => {
        ReactGA.initialize(trackingId,{ debug: process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_GA_DEBUG });
        console.log('ReactGA: tracking initial page', history.location);
        ReactGA.set({ dimension1: process.env.REACT_APP_VERSION });
        trackPage(history.location);
        firebase.auth.onAuthStateChanged(
            authUser => {
                if (authUser) {
                    console.log('ReactGA: setting userId');
                    ReactGA.set({ userId: authUser.uid });
                } else {
                    console.log('ReactGA: unsetting userId');
                    ReactGA.set({ userId: null });
                }
            });

        return history.listen(trackPage);
    }, [history, trackingId, firebase.auth]);

    return children;
}

GAListener.propTypes = {
    children: PropTypes.node,
    trackingId: PropTypes.string,
    history: PropTypes.shape({
        listen: PropTypes.func,
    }),
};

export default compose(
    withRouter,
    withFirebase
)(GAListener);
