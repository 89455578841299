import React from "react";
import "semantic-ui-css/semantic.min.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { toast } from 'react-toastify';

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import { ReportsTable, ReportView } from "../Reports";
import VisitBrowser from '../VisitBrowser';
import { VisitPage } from '../Visit';
import EnrollmentQueue from "../Admin/Enrollment";
import MediaBrowser from "../Admin/MediaBrowser";
import CompareVisits from "../Visit/CompareVisits";
import CustomerProfile from "../CustomerProfile";
import ViewMissedVisits from "../Admin/ViewMissedVisits";
import SystemStatusPage from "../Admin/SystemStatusPage";
import RemoteConfigManager from '../Admin/RemoteConfigManager';

import Recommendations from "../Sandbox/Recommendations";
import Home from "../VisitsFeed";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import { Container, Message, Menu } from "semantic-ui-react";
import "./app.css";

import UnsupportedBrowserToast from "../Navigation/UnsupportedBrowserSnackbar";
import GAListener from '../Analytics';

/*
import Moment from "moment-timezone";
import "moment/locale/en-il";
import "moment/locale/en-gb"
const detectedLocale = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : 'en';
console.log('Detected: ', detectedLocale);
Moment.locale([detectedLocale, 'en']);
console.log('Set: ', Moment.locale());
;*/

toast.configure();



console.log(process.env.REACT_APP_VERSION);

const App = () => (
  <Router>
    <GAListener trackingId={process.env.REACT_APP_GA_ID}>
      <UnsupportedBrowserToast />
      <Navigation />

      <Container className="main">
        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.REPORTS} component={ReportsTable} />
        <Route path={ROUTES.REPORT_VIEW} component={ReportView} />
        <Route exact path={ROUTES.VISIT_VIEW} component={VisitPage} />
        <Route path={ROUTES.COMPARE_VISITS} component={CompareVisits} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route exact path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.ENROLLMENT} component={EnrollmentQueue} />
        <Route path={ROUTES.MEDIA_BROWSER} component={MediaBrowser} />
        <Route path={ROUTES.VISITS_BROWSER} component={VisitBrowser} />
        <Route path={ROUTES.CUSTOMER_PROFILE} component={CustomerProfile} />
        <Route path={ROUTES.MISSED_VISITS} component={ViewMissedVisits} />
        <Route path={ROUTES.SYSTEM_STATUS} component={SystemStatusPage} />
        <Route path={ROUTES.REMOTE_CONFIG_MANAGER} component={RemoteConfigManager} />

        <Route path={ROUTES.BETA_RECOMMENDATIONS} component={Recommendations} />
        <Route exact path={'/home/old'} component={HomePage} />
      </Container>
    </GAListener>
  </Router>
);

export default withAuthentication(App);
