import React, { useState } from 'react';
import { Icon, Label, Header, Grid, Accordion } from "semantic-ui-react";
import { PersonRoles } from '../../../constants/roles'
import IdentificationsList from './identificationsList';
import UUID from '../../UI/uuid';

const VisitMetadataGlance = (props) => {

    const [ faceThumbnailsOpen, setFaceThumbnailsOpen] = useState(false);

    let person = props.personData;
    let idents = props.idents;
    let confidence = props.confidence;

    const onTitleClick = (e, data) => {
        setFaceThumbnailsOpen(!data.active);
    }

    return (
        <div>
            <Header as='h3'>
                <Header.Content>
                    <Icon name='user' color="orange" />
                    {person.role !== PersonRoles.anonymous ? person.name + " " : "Unidentified Customer "}
                    {person.role === PersonRoles.default
                        ? <Label horizontal color="orange">customer</Label>
                        : <Label basic={person.role === PersonRoles.employee} horizontal>{person.role}</Label>
                    }
                    <Label horizontal content={parseFloat(Math.round(confidence.max * 10000) / 100).toFixed(2) + "%"} /> <Label horizontal content={"avg. " + parseFloat(Math.round(confidence.avg * 10000) / 100).toFixed(2) + "%"} />
                    <Header.Subheader className="sub-metadata">
                        <Grid>
                            <Grid.Row style={{ paddingBottom: '2px' }}>
                                <Grid.Column width="4">
                                    <b>UUID:</b>
                                </Grid.Column>
                                <Grid.Column width="12">
                                    <UUID value={person.uuid} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ paddingTop: '2px' }}>
                                <Grid.Column width="4">
                                    {person.customerId && <b>Customer ID:</b>}
                                </Grid.Column>
                                <Grid.Column width="12">
                                    {person.customerId && person.customerId}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Header.Subheader>
                </Header.Content>
            </Header>
            <Accordion styled onTitleClick={onTitleClick} panels={
                [
                    {
                        key: 'faces',
                        title: 'All faces detected',
                        content: {
                            content: <IdentificationsList list={idents} maxConfidence={confidence.max} showImages={faceThumbnailsOpen} />
                        }
                    }
                ]
            } />
        </div>
    );
}

export default VisitMetadataGlance;