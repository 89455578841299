import React from 'react';
import { compose } from "recompose";
import { Header, Grid, Segment, Divider} from "semantic-ui-react";
import { withLoading, withPaginated, withInfiniteScroll } from '../../Lists';

import MediaView from './MediaView'

const EmptyTableView = props => {
    return (
        <Grid>
            <Grid.Column textAlign="center">
                <Segment>
                    <Header as="h3">
                        No images to display
                    </Header>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

const MediaTable = (props) => {

    let { media } = props;
    //let visitsToDisplay = visits.filter((visit => (filter && filter.roles) ? filter.roles[visit.data.person.role] : true))

    return (
        <div>
            <br />
            {media.map(media => (
                <div key={media.id}>
                    <MediaView media={media}/>
                    <Divider />
                    <br />
                </div>
            ))}
            { media.length === 0 && <EmptyTableView/>  }
        </div>
    );
}

const paginatedCondition = props =>
    props.page !== null && !props.isLoading && props.isError;

const infiniteScrollCondition = props =>
    (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)
    && props.media.length
    && !props.isLoading
    && !props.isError;

const loadingCondition = props =>
    props.isLoading;


export default compose(
    withPaginated(paginatedCondition),
    withInfiniteScroll(infiniteScrollCondition),
    withLoading(loadingCondition)
)(MediaTable);