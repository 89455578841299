import React, { Component } from "react";
import { compose } from "recompose";
import moment from "moment-timezone";

import { Header, Segment, Form, Button, Icon } from "semantic-ui-react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from "moment-timezone";
import queryString from 'query-string';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from 'react-tooltip';

import { withAdminAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import * as ROUTES from '../../../constants/routes';

import MediaTable from "./MediaTable";




import './mediaBrowser.css';

const AllLocationsValue = 'all';

const applyPagnationResult = result => prevState => ({
    media: [...prevState.media, ...result.media],
    nextPage: result.nextPage,
    isError: false,
    isLoading: false
});

const applySetError = () => prevState => ({
    isError: true,
    isLoading: false
});

class MediaBrowser extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const queryParams = this.props.location.search ? queryString.parse(this.props.location.search) : {};
        this.state = {
            media: [],
            nextPage: null,
            isLoading: false,
            isError: false,

            locations: [],
            locationOptions: [ { value: AllLocationsValue, text: 'All Locations' } ],
            locationFilter: queryParams.locationId ? queryParams.locationId : AllLocationsValue,

            startDate: queryParams.date ? new Date(queryParams.date) : today,
            endDate: queryParams.date ? new Date(queryParams.date) : today,

            //userFilter: queryString.parse(this.props.location.search).uuid ? queryString.parse(this.props.location.search).uuid : '',
            //comapreWith: null,
            
            copiedLink: false,
        };
    }

    componentDidMount() {
        this.props.firebase.locations.fetch().then(locations => {
            var locationOptions = [...this.state.locationOptions, ...locations.map(location => ({ value: location.id, text: location.data.name }))];
            this.setState({ locations: locations, locationOptions: locationOptions, locationFilter: locationOptions[0].value });
        }).catch(error => {
            console.error("Error fetching locations", error);
            this.setState(applySetError());
        });
        // if we have query params when mounted - run the query immediately 
        if (this.props.location.search) {
            this.fetchMedia();
        }
    }

    onPaginationSubmit = () => {

        if (!!this.state.nextPage) {
            this.setState({ isLoading: true });
            this.state
                .nextPage()
                .then(results => {
                    this.setState(applyPagnationResult(results));
                })
                .catch(error => {
                    console.error(error);
                    this.setState(applySetError());
                });
        }
    };

    onLocationSelect = (event, data) => {
        this.setState({ locationFilter: data.value });
    }

    handleStartDateChange = (date) => {
        // currently allow choosing a single day only
        this.setState({ startDate: date,  endDate: date });
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    }

    /* handleUserUuidInput = (event, data) => {
        this.setState({ userFilter: data.value });
    } */

    fetchMedia = async () => {
        this.setState({ media: [], isLoading: true, copiedLink: false });

        let startDateString = moment(this.state.startDate);
        let endDateString = moment(this.state.endDate).format('YYYY-MM-DD');

        let options = {
            locationId: this.state.locationFilter !== AllLocationsValue ? this.state.locationFilter : null,
            startTimestamp: Moment(this.state.startDate).toDate(),
            stopTimestamp: Moment(endDateString).endOf('day').utc().toDate(),
            limit: 50,

            detectedOnly: false,
        }
        try {
            let results = await this.props.firebase.mediaData.fetch(options);
            this.setState(applyPagnationResult(results));
        } catch (error) {
            this.setState(applySetError());
            console.error('MediaBrowser:', error);
        }
    }

    setVisitCompared = visitID => {
        this.setState({ comapreWith: visitID });
    }

    render() {
        const { media, locations, locationOptions, locationFilter, nextPage, isError, isLoading, comapreWith, userFilter, copiedLink } = this.state;
        let now = new Date();
        const { startDate, endDate } = this.state;
        let startDateString = moment(startDate).format('YYYY-MM-DD');

        return (
            <div>
                <Segment attached="bottom">
                    <Header as="h1">Media Browser</Header>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown
                                onChange={this.onLocationSelect}
                                defaultValue="all"
                                selection
                                options={locationOptions}
                                label="Location" />
                            <Form.Field>
                                <label>Date</label>
                                <DatePicker
                                    className='datePicker'
                                    selected={startDate}
                                    selectsStart
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="yyyy-MM-d HH:mm"
                                    startDate={startDate}
                                    maxDate={now}
                                    onChange={this.handleStartDateChange}
                                />
                            </Form.Field>
                            {/* <Form.Field>
                                <label>To</label>
                                <DatePicker
                                    className='visitDatePicker'
                                    selected={endDate}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={endDate}
                                    onChange={this.handleEndDateChange}
                                    minDate={startDate}
                                    maxDate={now}
                                />
                            </Form.Field> */}
                        </Form.Group>
                        <Form.Group widths="equal">                
                            <Form.Field>
                                <span>
                                    <Button color="orange" onClick={this.fetchMedia} disabled={false}> Go </Button>
                                    {<CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}${ROUTES.MEDIA_BROWSER}?date=${startDateString}&locationId=${locationFilter}`} onCopy={() => this.setState({ copiedLink: true })}>
                                        <Icon name="chain" style={{ cursor: "pointer" }} color={copiedLink ? "green" : null} data-tip={"Copy link to clipboard"} />
                                    </CopyToClipboard>}
                                    <ReactTooltip effect="solid" place="right" />
                                </span>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>
                <MediaTable
                    media={media}
                    isLoading={isLoading}
                    isError={isError}
                    nextPage={nextPage}
                    onPaginatedSearch={this.onPaginationSubmit}
                    locations={locations}
                />
            </div>
        );
    }
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(MediaBrowser);
//test
