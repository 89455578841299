import React from 'react';
import { CaazamImageView, useImageUrl, openFullpageImage  } from '../../Images';

import { Header, Grid, Table } from "semantic-ui-react";

const FacesTable = (props) => {
    let { faces } = props;

    return (
        <Table basic='very' celled >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={3}>Face ID</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Face Rect</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Age</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Gender</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Identified</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Conf</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Realtime Identified</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Conf</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(faces).map(face => (<Table.Row key={face}>
                    <Table.Cell>
                        {face}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceRectangle.width}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceAttributes.age}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceAttributes.gender}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceIdentify && faces[face].faceIdentify.length > 0 ? `${faces[face].faceIdentify[0].uuid}` : ''}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceIdentify && faces[face].faceIdentify.length > 0 ? `${faces[face].faceIdentify[0].confidence}` : ''}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceRealtimeIdentify && faces[face].faceRealtimeIdentify.length > 0 ? `${faces[face].faceRealtimeIdentify[0].uuid}` : ''}
                    </Table.Cell>
                    <Table.Cell>
                        {faces[face].faceRealtimeIdentify && faces[face].faceRealtimeIdentify.length > 0 ? `${faces[face].faceRealtimeIdentify[0].confidence}` : ''}
                    </Table.Cell>
                </Table.Row>))}
            </Table.Body>
        </Table>
    )
}

const MediaView = (props) => {

    let { media } = props;
    let faces = null;
    const thumbnailUrl = useImageUrl(media.id, 'thumb');

    if (media.data.detections && media.data.detections.coreFace && media.data.detections.coreFace.faces) {
        if (Object.keys(media.data.detections.coreFace.faces).length > 0) {
            faces = media.data.detections.coreFace.faces;
        }
    }

    return (
        <div>
            <Grid columns={2} divided>
                <Grid.Column width={9}>
                    <Table basic='very' celled collapsing>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h5'>Media UUID</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {media.id}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h5'>Location</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {media.data.source.locationId} - {media.data.source.deviceId}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h5'>Timestamp</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {media.data.timestamp.toDate().toISOString()}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Header as='h5'>Event type</Header>
                                </Table.Cell>
                                <Table.Cell>
                                    {media.data.source.event}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={7}>
                    {thumbnailUrl && <CaazamImageView src={thumbnailUrl}  onClick={() => openFullpageImage(media.id)}/>}
                </Grid.Column>
            </Grid>
            {faces && <FacesTable faces={faces} />}
        </div>
    );
}

export default MediaView;